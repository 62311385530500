import { RangeSlider } from 'components/Scl/Form/Field/RangeSlider'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { useRange } from 'hooks/range'
import { Options } from 'nouislider'

export interface Props extends HasClassName {
  value: any
  input: any
}

export const InvoicePeriodSlider: FunctionComponent<Props> = ({ input, value, className }) => {
  const { asStepValues } = useRange()

  const options: Options = {
    range: asStepValues([10, 14, 30, 45, 60, 90]),
    start: Number(value),
    connect: 'lower',
    snap: true,
  }

  const onInputHandler = (event: string[]) => {
    const value = parseInt(event[0])
    input(value)
  }

  return (
    <RangeSlider
      className={className}
      options={options}
      input={onInputHandler}
    />
  )
}
