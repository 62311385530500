import { FunctionComponent, useState } from 'react'
import { HasClassName } from '~/models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { useLeads } from '~/hooks/leads'
import LeadSource from '~/enums/LeadSource'
import { Icon } from '../Icon/Icon'
import { Btn } from '../Scl/Form/Button/Btn'
import { MarkdownRenderer } from '../Scl/Form/Conversational/MarkdownRenderer'
import { Field } from '../Scl/Form/Field/Field'
import { Container } from '../Scl/Layout/Container/Container'
import { HeadingStoryblok } from './HeadingStoryblok'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validateLuhn } from '@capcito/ui-validation'
import * as Yup from 'yup'
import classNames from 'classnames'
import { useLocale } from '~/hooks/locale'
import { useTranslations } from '~/hooks/translations'
import { BeConfig } from '~/config/be'

export interface Props extends Blok {
  partner: any,
  heading: any[],
  text: any,
  uspHeading: any[],
  usps: any[],
  formHeading: any[],
  formText: any,
  formCta: any[],
  namePlaceholder: string,
  nameLabel: string,
  formOrganizationNumberPlaceholder: string,
  formOrganizationNumberLabel: string,
  formPhoneNumberPlaceholder: string,
  formPhoneNumberLabel: string,
  formEmailPlaceholder: string,
  formEmailLabel: string,
  formDisclaimer: any,

}

export const HeroGetStartedSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const { culture } = useLocale()
  const translations = useTranslations(culture)

  const schema = () => {
    return Yup.object().shape({
      email: Yup.string().email().required(),
      phone: Yup.string().required(),
      companyIdentityNumber: Yup.string()
        .required()
        .test('luhn', `${translations?.ssn}`, (value) => validateLuhn(`${value}`)),
    })
  }

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema()),
    mode: 'onBlur',
  });

  const { createOrUpdateLead } = useLeads()
  const [loading, setLoading] = useState(false)

  async function onFormSubmit(data: any, event: any) {
    event.preventDefault()
    setLoading(true)

    const payload = {
      source: LeadSource.ONBOARDING,
      companyIdentityNumber: data.companyIdentityNumber,
      phone: data.phone,
      email: data.email,
    }

    createOrUpdateLead(payload)
      .then(() => {
        // redirect user to sign up page
        window.location.href= BeConfig.baseUrl
      })
      .catch(response => {
        setLoading(false)
      })
  }

  return (
    <div className={classNames('py-16 lg:py-22', className)}>
      <Container width="xl">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/2 xl:w-7/12 lg:pr-6">
            <div className="lg:pl-8 lg:pt-6">
              <HeadingStoryblok
                blok={blok.heading[0]}
                className="max-w-xl mb-4"
              />

              <MarkdownRenderer
                className="max-w-xl text-lg"
                markdown={blok.text}
              />
            </div>

            <hr className="w-full h-px my-8 bg-gray-200 border-0 lg:mt-32 lg:mb-12" />

            <div className="hidden lg:block lg:pl-20 lg:pr-20">
              <HeadingStoryblok
                blok={blok.uspHeading[0]}
                className="mb-8"
              />

              <div className="space-y-2">
                {blok.usps.map((usp, index) => (
                  <div
                    className="flex items-center px-4 py-3 bg-white rounded-md"
                    key={index}
                  >
                    <Icon
                      className="w-6 h-6 mr-3 text-blue-500"
                      icon={usp.icon}
                    />

                    <div className="font-bold text-indigo-500">
                      { usp.text }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 xl:w-5/12 lg:pl-6">
              <form
                className="p-8 space-y-4 bg-white rounded shadow-xl lg:px-16 lg:py-12"
                onSubmit={handleSubmit(onFormSubmit)}
              >
                <HeadingStoryblok
                  blok={blok.formHeading[0]}
                  className="mb-8"
                />

                <Field
                  placeholder={blok.formOrganizationNumberPlaceholder}
                  label={blok.formOrganizationNumberLabel}
                  disabled={loading}
                  errors={errors.companyIdentityNumber ? [errors.companyIdentityNumber.message] : []}
                  type="text"
                  {...register('companyIdentityNumber')}
                />

                <Field
                  placeholder={blok.formPhoneNumberPlaceholder}
                  label={blok.formPhoneNumberLabel}
                  disabled={loading}
                  errors={errors.phone ? [errors.phone.message] : []}
                  type="text"
                  {...register('phone')}
                />

                <Field
                  placeholder={blok.formEmailPlaceholder}
                  label={blok.formEmailLabel}
                  disabled={loading}
                  errors={errors.email ? [errors.email.message] : []}
                  type="text"
                  {...register('email')}
                />

                <MarkdownRenderer
                  className="text-xs text-indigo-500 text-opacity-60"
                  markdown={blok.formDisclaimer}
                />

                <div className="pt-2">
                  <Btn
                    data-track={blok.formCta[0].trackingId}
                    disabled={errors.length}
                    loading={loading}
                    type="submit"
                  >
                    { blok.formCta[0].text }
                  </Btn>
                </div>
              </form>
          </div>
        </div>
      </Container>
    </div>
  )
}
