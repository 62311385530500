import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { useDate } from 'hooks/date'
import classNames from 'classnames'

export interface Props extends HasClassName {
  tags?: string
  name?: string
  date?: string
}

export const MediaTagline: FunctionComponent<Props> = ({ name, date, tags, className}) => {
  const { toDayMonth } = useDate()

  function getText() {
    if (name && date) {
      return `${name}, ${toDayMonth(date)}`
    } else if (name) {
      return name
    } else if (date) {
      return toDayMonth(date)
    }

    return ''
  }

  return (
    <div className={classNames('flex space-x-2 text-xs text-indigo-500 text-opacity-60 md:text-sm', className)}>
      {tags && (
        <div className="font-bold uppercase">
          {tags}
        </div>
      )}

      <div className="font-semibold">
        {getText()}
      </div>
    </div>
  )
}
