import { FunctionComponent, useState } from 'react'
import { HasClassName } from '~/models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validateLuhn } from '@capcito/ui-validation'
import * as Yup from 'yup'
import { FadeTransition } from '~/components/Scl/Tools/Transitions/FadeTransition'
import { Btn } from '~/components/Scl/Form/Button/Btn'
import { Field } from '~/components/Scl/Form/Field/Field'
import LeadSource from '~/enums/LeadSource'
import { Heading } from '~/components/Scl/Typography/Heading'
import { useLeads } from '~/hooks/leads'
import GTMEvent from '~/enums/GTMEvent'
import { useLocale } from '~/hooks/locale'
import { useTranslations } from '~/hooks/translations'

export interface Props extends Blok {
  submittedTitle: string,
  submittedText: string,
  submittedCta: any[],
  cta: any[],
  namePlaceholder: string,
  nameLabel: string,
  organizationNumberPlaceholder: string,
  organizationNumberLabel: string,
  phoneNumberPlaceholder: string,
  phoneNumberLabel: string,
  emailPlaceholder: string,
  emailLabel: string,
}

export const LeadForm: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const { culture } = useLocale()
  const translations = useTranslations(culture)

  const schema = () => {
    return Yup.object().shape({
      name: Yup.string().required(),
      email: Yup.string().email().required(),
      phone: Yup.string().required(),
      companyIdentityNumber: Yup.string()
        .required()
        .test('luhn', `${translations?.ssn}`, (value) => validateLuhn(`${value}`)),
    })
  }

  const { register, handleSubmit, reset, setError, formState: { errors } } = useForm({
    resolver: yupResolver(schema()),
    mode: 'onBlur',
  });

  const { createOrUpdateLead } = useLeads()
  const [overlay, setOverlay] = useState<boolean>(false)



  const toggleOverlay = () => {
    setOverlay((prev) => !prev)
  }

  function firstName ({name} : any) {
    if (!name.length || !name.includes(' ')) {
      return null
    }

    return name
      .split(' ')
      .slice(0, -1)
      .join(' ');
  }

  function surname ({name} : any) {
    if (!name.length || !name.includes(' ')) {
      return null
    }

    return name
      .split(' ')
      .slice(-1)
      .join(' ');
  }

  const onFormSubmit = (data: any, event: any) => {
    event.preventDefault()

    const payload = {
      companyIdentityNumber: data.companyIdentityNumber,
      source: LeadSource.CONTACT_FORM,
      firstName: firstName(data),
      surname: surname(data),
      phone: data.phone,
      email: data.email
    }

    createOrUpdateLead(payload, { validateUnique: false })
      .then(() => {
        window.dataLayer.push({ event: GTMEvent.LEAD_FORM })
        toggleOverlay()
        reset()
      })
  }

  return (
    <form
      className="px-6 py-10 bg-white shadow md:max-w-lg lg:p-16"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      <div className="relative">
        <FadeTransition>
          {overlay && (
            <div className="absolute top-0 left-0 z-10 flex flex-col justify-between w-full h-full bg-white">
              <div className="w-full">
                <Heading className="mb-4">
                  { blok.submittedTitle }
                </Heading>

                <div className="text-lg">
                  { blok.submittedText }
                </div>
              </div>

              <div className="w-full">
                <Btn
                  data-track={blok.submittedCta[0].trackingId}
                  onClick={toggleOverlay}
                  theme="secondary"
                  type="button"
                >
                  { blok.submittedCta[0].text }
                </Btn>
              </div>
            </div>
          )}
        </FadeTransition>

        <div className="space-y-6">
          <Field
            placeholder={blok.namePlaceholder}
            label={blok.nameLabel}
            errors={errors.name? [errors.name.message] : []}
            type="text"
            {...register('name')}
          />

          <Field
            placeholder={blok.organizationNumberPlaceholder}
            label={blok.organizationNumberLabel}
            errors={errors.companyIdentityNumber? [errors.companyIdentityNumber.message] : []}
            type="text"
            {...register('companyIdentityNumber')}
          />

          <Field
            placeholder={blok.phoneNumberPlaceholder}
            label={blok.phoneNumberLabel}
            errors={errors.phone? [errors.phone.message] : []}
            type="text"
            {...register('phone')}
          />

          <Field
            placeholder={blok.emailPlaceholder}
            label={blok.emailLabel}
            errors={errors.email? [errors.email.message] : []}
            type="email"
            {...register('email')}
          />

          <div className="flex items-center pt-2">
            <div className="w-full lg:w-5/12">
              <Btn
                data-track={blok.cta[0].trackingId}
                disabled={errors.length}
                theme="primary"
                type="submit"
              >
                { blok.cta[0].text }
              </Btn>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
