import { FunctionComponent, ReactNode } from 'react'
import { HasClassName } from 'models/HasClassName'
import { Background } from 'models/Background'
import classNames from 'classnames'

type Size = 'xl'| 'xs' | 'md' | 'lg' | 'sm'

export interface Props extends HasClassName {
  background: Background
  children: ReactNode
  size: Size
}

function getSpacingClass (size: Size) {
  switch(size) {
    case 'xl':
      return 'py-20 md:py-40'
    case 'lg':
      return 'py-20 md:py-32'
    case 'md':
      return 'py-16 md:py-24'
    case 'sm':
      return 'py-12 md:py-20'
    case 'xs':
      return 'py-12 md:py-6'
  }
}

function getBackgroundClass (background: Background) {
  switch(background) {
    case 'WHITE':
      return 'bg-white'
    case 'GRAY':
      return 'bg-gray-100'
    case 'INDIGO':
      return 'bg-indigo-500'
    case 'IVORY':
      return 'bg-ivory-500'
    case 'LIGHT_IVORY':
      return 'bg-ivory-300'
  }
}

export const SpacingWrapper: FunctionComponent<Props> = ({ className, size, background, children }) => {
  const spacingClass = getSpacingClass(size)
  const backgroundColorClass = getBackgroundClass(background)

  const classList = `${spacingClass} ${backgroundColorClass}`

  return (
    <div className={classNames(classList, className)}>
      { children }
    </div>
  )
}
