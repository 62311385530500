import { MediaTagline } from '~/components/MediaTagline/MediaTagline'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'
import Image from 'next/image'

export interface Props extends HasClassName {
  image: string
  title: string
  preamble: string
  date: string
  author?: string
  tags?: string
}

export const LargeMediaTeaser: FunctionComponent<Props> = ({ image, title, preamble, date, author, tags, className }) => {
  return (
    <div className={classNames('flex flex-col pb-6 group', className)}>
      <div>
        <img
          className="w-full mb-6"
          src={image}
          // TODO: maybe add a alt?
          alt=""
        />
      </div>
      <div>
        <div className="mb-2 font-serif text-3xl font-bold leading-none md:text-4xl lg:text-5xl group-hover:underline">
          {title}
        </div>
        <div className="text-lg text-indigo-500 text-opacity-60">
          {preamble}
        </div>
        <div className="flex flex-wrap mt-4 text-xs text-indigo-500 text-opacity-60">
          <MediaTagline
            name={author}
            date={date}
            tags={tags}
          />
        </div>
      </div>
    </div>
  )
}
