import { FadeScaleTransition } from 'components/Scl/Tools/Transitions/FadeScaleTransition'
import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { CollapseProvider } from 'components/Scl/Tools/Collapse/CollapseProvider'
import { Container } from 'components/Scl/Layout/Container/Container'
import { ChevronDownIcon } from 'components/Scl/Icon/ChevronDownIcon'
import { LocaleSwitcher } from 'components/LocaleSwitcher/LocaleSwitcher'
import { CapcitoLogo } from 'components/Scl/Logo/CapcitoLogo'
import { Heading } from 'components/Scl/Typography/Heading'
import { LocaleFlag } from 'components/LocaleFlag/LocaleFlag'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { Blok } from '@storyblok/react'
import { useLink } from '~/hooks/link'
import NextLink from 'next/link'

export interface Props extends Blok {
  contactTitle: any
  contactText: any
  featuredProducts: any[]
  linksFirstColumnTitle: any
  linksFirstColumn: any[]
  linksSecondColumnTitle: any
  linksSecondColumn: any[]
  partnersTitle: any
  partners: any[]
  companyName: any
  companyAddress: any
}

export const GlobalFooter: FunctionComponent = () => {
  const { footer } = useGlobalContext()
  const { asPath } = useRouter()
  const { link } = useLink()

  const current = asPath.split('/')[1]

  if (!footer) {
    return null
  }

  return (
    <footer className="py-12 text-white bg-indigo-500 lg:py-16">
      <Container
        className="flex flex-col justify-between lg:flex-row"
        width="2xl"
      >
        <NextLink href={link('/')}>
          <a>
            <CapcitoLogo svgClass="w-24" />
          </a>
        </NextLink>

        <div className="mt-6 lg:mt-0">
          <Heading
            className="mb-2"
            size="tiny"
            tag="h2"
          >
            {footer.contactTitle}
          </Heading>

          <div className="opacity-60">
            <MarkdownRenderer
              markdown={footer.contactText}
            />
          </div>
        </div>
      </Container>

      <div className="py-px my-10 bg-white opacity-10 lg:my-16" />

      <Container
        className="flex flex-wrap"
        width="2xl"
      >
        <div className="w-full mb-8 space-y-4 md:w-1/2 lg:w-3/12 lg:mb-0">
          {footer.featuredProducts.map((item, index) => (
            <Anchor
              className="block text-2xl leading-none text-ivory-500 hover:text-white hover:underline"
              to={item.link}
              key={index}
            >
              {item.text}
            </Anchor>
          ))}
        </div>

        <div className="flex flex-wrap w-full md:w-1/2 lg:w-6/12">
          <div className="w-full mb-6 md:w-1/2 lg:w-1/3">
            <div className="pb-4 text-lg font-bold leading-none">
              {footer.linksFirstColumnTitle}
            </div>

            <div className="space-y-4">
              {footer.linksFirstColumn.map((item, index) => (
                <Anchor
                  className="block leading-none opacity-60 hover:opacity-100"
                  to={item.link}
                  key={index}
                >
                  {item.text}
                </Anchor>
              ))}

            </div>
          </div>

          <div className="w-full mb-6 md:w-1/2 lg:w-1/3">
            <div className="pb-4 text-lg font-bold leading-none">
              {footer.linksSecondColumnTitle}
            </div>

            <div className="space-y-4">
              {footer.linksSecondColumn.map((item, index) => (
                <Anchor
                  className="block leading-none opacity-60 hover:opacity-100"
                  to={item.link}
                  key={index}
                >
                  {item.text}
                </Anchor>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full space-y-10 lg:w-3/12 md:space-y-0 lg:space-y-10 md:flex lg:block lg:pl-10">
          <div className="w-full md:w-1/2 lg:w-full">
            <div className="pb-4 text-lg font-bold leading-none">
              {footer.partnersTitle}
            </div>
            <div className="grid grid-cols-2 gap-y-6">
              {footer.partners.map((partner, index) => (
                <img
                  // todo: Lazy?
                  src={partner.filename}
                  alt={partner.alt}
                  key={index}
                  className="h-6"
                />
              ))}

            </div>
          </div>
        </div>
      </Container>

      <div className="py-px my-10 bg-white opacity-10 lg:my-16" />

      <Container width="2xl">
        <div className="flex">
          <div className="flex-grow mr-4 opacity-60">
            <div className="block sm:inline-block">
              {footer.companyName}
            </div>
            <span className="hidden lg:inline-block"> – </span>
            <div className="block sm:inline-block">
              {footer.companyAddress}
            </div>
          </div>
          <CollapseProvider>
            {({ open, toggle }) => (
              <>
                <Btn
                  className="relative flex w-24 h-12 ml-auto"
                  onClick={toggle}
                  theme="outline-white"
                >
                  <LocaleFlag
                    className="absolute left-0 w-6 ml-5 transform -translate-y-1/2 top-1/2"
                    locale={{ code: current }}
                  />

                  <ChevronDownIcon
                    className="absolute right-0 w-5 h-5 mr-5 transform -translate-y-1/2 top-1/2"
                  />
                </Btn>

                <FadeScaleTransition>
                  {open && <LocaleSwitcher current={current} />}
                </FadeScaleTransition>
              </>
            )}
          </CollapseProvider>
        </div>
      </Container >
    </footer >
  )
}
