
import { BusinessLoanPeriodSlider } from 'components/Slider/BusinessLoanPeriodSlider'
import { AmountRangeSlider } from 'components/Slider/AmountRangeSlider'
import { BaseCalculator } from 'components/Storyblok/Partials/Hero/BaseCalculator'
import { FormLabel } from 'components/Scl/Typography/FormLabel'
import { Heading } from 'components/Scl/Typography/Heading'
import { BusinessLoan, Month } from '@capcito/ui-core-logic'
import { FunctionComponent, useState } from 'react'
import { Blok, BlokProps } from '@storyblok/react'
import { formatMoney } from '@capcito/ui-money'
import { useRouter } from 'next/router'

export interface Props extends Blok {
  text: string
  interest: any
  cta: any
  title: any
  amountLabel: any
  periodLabel: any
  perPeriod: any
  interestLabel: any
  periodAffix: any
  amortizationLabel: any
  totalRepaymentLabel: any
  disclaimer: any
  periodUnit: string
}

export const BusinessLoanCalculator: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const { locale } = useRouter()

  const culture = locale ?? 'sv'

  const [amount, setAmount] = useState(100000)
  const [period, setPeriod] = useState('12')

  const weeks = Month.toWeeks(parseInt(period))
  const formattedAmount = formatMoney(amount, 'SEK', culture)

  const interest = parseFloat(`${blok.interest / 100}`)

  const totalCost = BusinessLoan.cost(amount, interest, weeks)

  const weeklyCost = BusinessLoan.annuity(amount, interest, weeks)

  const toMoney = (value: number) => {
    return formatMoney(value, 'SEK', culture)
  }

  const result = {
    weeklyInterestAmount: toMoney(weeklyCost - (amount / weeks)),
    weeklyAmortization: toMoney(amount / weeks),
    weekly: toMoney(weeklyCost),
    total: toMoney(totalCost),
  }

  return (
    <BaseCalculator
      btn={blok.cta[0]}
      title={blok.title}
      leftCol={<>
        <div className="flex mb-2 xl:mb-4">
          <div className="w-1/3">
            <FormLabel>
              {blok.amountLabel}
            </FormLabel>
          </div>

          <div className="w-2/3 text-right">
            <Heading
              className="text-blue-500"
              size="tiny"
            >
              {formattedAmount}
            </Heading>
          </div>
        </div>

        <AmountRangeSlider onInput={setAmount} value={amount} />
      </>}
      rightCol={
        <>
          <div className="flex mb-2 xl:mb-4">
            <div className="w-1/2">
              <FormLabel>
                {blok.periodLabel}
              </FormLabel>
            </div>

            <div className="w-1/2 text-right">
              <Heading
                className="text-blue-500"
                size="tiny"
              >
                {`${period} ${blok.periodUnit}`}
              </Heading>
            </div>
          </div>

          <BusinessLoanPeriodSlider input={setPeriod} value={period} />
        </>}
      result={
        <>
          <Heading
            size={{ base: 'small', lg: 'regular' }}
            className="text-blue-500"
          >
            {result.weekly}
          </Heading>
          <div className="text-sm font-semibold text-indigo-500 text-opacity-50">
            {blok.perPeriod}
          </div>
        </>}
      footer={
        <>
          <div className="grid max-w-2xl grid-cols-2 gap-2 pb-4 sm:grid-cols-3">
            <div>
              <div className="mb-1 text-sm">
                {blok.interestLabel}
              </div>
              <FormLabel>
                {result.weeklyInterestAmount}/{blok.periodAffix}
              </FormLabel>
            </div>

            <div>
              <div className="mb-1 text-sm">
                {blok.amortizationLabel}
              </div>
              <FormLabel>
                {result.weeklyAmortization}/{blok.periodAffix}
              </FormLabel>
            </div>

            <div>
              <div className="mb-1 text-sm">
                {blok.totalRepaymentLabel}
              </div>
              <FormLabel>
                {result.total}
              </FormLabel>
            </div>
          </div>

          <p className="text-sm font-semibold text-indigo-500 text-opacity-50">
            {blok.disclaimer}
          </p>
        </>}
    />
  )
}
