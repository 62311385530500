import { HasClassName } from 'models/HasClassName'
import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

interface LottieProps extends HasClassName {
  path: any,
  assetsPath: any,
  width?: string
  height?: string
  autoplay: boolean,
  loop: boolean,
}

export const Lottie = ({ path, autoplay, loop, assetsPath, className }: LottieProps) => {
  const container = useRef<HTMLDivElement>(null)
  const lottieInstance = useRef<any>()

  useEffect(() => {
    if (container.current) {
      lottieInstance.current = lottie.loadAnimation({
        path,
        autoplay,
        loop,
        container: container.current,
        assetsPath,
      })
    }

    return () => {
      if (lottieInstance.current) {
        lottieInstance.current.destroy()
      }
    }
  }, [path])

  return <div className={className} ref={container}/>
}
