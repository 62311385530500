import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Question {
  markdown: string
  title: string
}

export interface Props extends HasClassName {
  questions: Question[]
}

export const StructuredData: FunctionComponent<Props> = ({ className, questions }) => {
  return (
    <div
      itemType="https://schema.org/FAQPage"
      className="hidden"
      data-nosnippet
      itemScope
    >
      { questions.map((question, index) => (
        <div
          itemType="https://schema.org/Question"
          itemProp="mainEntity"
          key={ index }
          itemScope
        >
          <div itemProp="name">
            { question.title }
          </div>

          <div
            itemType="https://schema.org/Answer"
            itemProp="acceptedAnswer"
            itemScope
          >
            {/* TODO */}
            {/* <MarkdownRenderer
              markdown={ question.markdown }
              itemProp="text"
            /> */}
          </div>
        </div>
      ))}
    </div>
  )
}
