import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const FactoringIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      d="M3 23L4.5 21.5L6 23L7.5 21.5L9 23L10.5 21.5L12 23L13.5 21.5L15 23L16.5 21.5L18 23L19.5 21.5L21 23V1L19.5 2.5L18 1L16.5 2.5L15 1L13.5 2.5L12 1L10.5 2.5L9 1L7.5 2.5L6 1L4.5 2.5L3 1V23ZM18 9H6V7H18V9ZM18 13H6V11H18V13ZM18 17H6V15H18V17Z"
      fill="currentColor"
    />
    </svg>
  )
}
