import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { FunctionComponent, useEffect, useState } from 'react'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { StoryblokButton } from 'models/StoryblokButton'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { Blok } from '@storyblok/react'

const STORAGE_ITEM = 'cookie-consent'

export interface Props extends Blok {
  title: string
  text: string
  cta: StoryblokButton[]
}

export const CookieConsent: FunctionComponent = () => {
  const [consent, setConsent] = useState(false)
  const [loading, setLoading] = useState(true)
  const { cookieConcent } = useGlobalContext()

  useEffect(() => {
    setConsent(!!localStorage.getItem(STORAGE_ITEM) || false)
    setLoading(false)
  }, [])

  const needConsent = () => loading ? false : !consent

  const giveConsent = () => {
    setConsent(true)
    localStorage.setItem(STORAGE_ITEM, String(Date.now()))
  }

  const renderer = {
    link: (href: string, title: string, text: string) => `<a class="inline-block font-bold text-blue-500 hover:underline" href="${href}">${text}</a>`,
  }

  return needConsent() && cookieConcent ? (
    <div className="fixed bottom-0 left-0 right-0 z-40">
      <div className="items-center p-6 m-4 bg-white rounded-lg shadow sm:flex sm:p-8">
        <div className="flex-grow">
          <h2 className="text-lg font-bold">
            { cookieConcent.title }
          </h2>

          <MarkdownRenderer
            markdown={cookieConcent.text}
            renderer={renderer}
          />
        </div>

        <div className="flex-shrink-0 mt-4 sm:ml-4 sm:mt-0">
          <Btn
            theme={cookieConcent.cta[0].theme}
            onClick={giveConsent}
            className="w-full"
          >
            {cookieConcent.cta[0].text}
          </Btn>
        </div>
      </div>
    </div>
  ) : null
}
