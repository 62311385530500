import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Blok, BlokProps } from '@storyblok/react'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'

interface Props extends Blok {
  background: Background
}

export const TrustpilotSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <SpacingWrapper
      background={blok.background}
      size="sm"
    >
      <Container width="xl">
        <div
          data-businessunit-id="5bf3d7c2608c550001c8469e"
          data-template-id="53aa8912dec7e10d38f59f36"
          className="trustpilot-widget"
          data-tags="SelectedReview"
          data-review-languages="sv"
          data-style-height="140px"
          data-style-width="100%"
          data-locale="sv-SE"
          data-theme="light"
        >
          <a
            href="https://se.trustpilot.com/review/www.capcito.com"
            target="_blank"
            rel="noreferrer"
          />
        </div>
      </Container>
    </SpacingWrapper>
  )
}
