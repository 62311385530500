import { FunctionComponent, useEffect, useState } from 'react'
import { HasClassName } from 'models/HasClassName'
import { useMarkdown } from 'hooks/markdown'

export interface Props extends HasClassName{
  markdown: string
  renderer?: Record<any, any>
}

export const MarkdownRenderer: FunctionComponent<Props> = ({ className, markdown, renderer }) => {
  const [html, setHtml] = useState<string>('')
  const { parse } = useMarkdown()

  useEffect(() => {
    setHtml(parse(markdown, renderer))
  }, [markdown])

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{__html: html}}
    />
  )
}
