import { LinkField } from '@storyblok/react'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { BeConfig } from '~/config/be'

export const useLink = () => {
  const [locale, setLocale] = useState<string|null>()
  const { asPath } = useRouter()

  useEffect(() => {
    setLocale(asPath.replace('/', '').split('/')[0])
  }, [asPath])

  function linkIs(path: string) {
    const currentPath = asPath
      .replace(`/${locale}/`, '')

    return path === currentPath
  }

  function link(path: string): string {
    const culture = locale

    if (path === '/') {
      return `/${culture}`
    }

    return `/${culture}/${path}`
  }

  function tel(number: string): string {
    return `tel:${number}`
  }

  function isAnchor(link: LinkField): boolean {
    return !!link.url && link.url.startsWith('#')
  }

  function isExternalUrl(link: LinkField): boolean {
    return link.linktype !== 'story' && (link.cached_url?.startsWith('http://') || link.cached_url?.startsWith('https://') || link.cached_url.includes('{borrowerExperienceBaseUrl}'))
  }

  function linkToHref(link: LinkField, query: Record<string, string> = {}): string {
    if (isAnchor(link)) {
      return link.url
    }

    if (isExternalUrl(link)) {
      return link.url
        .replace('{borrowerExperienceBaseUrl}', BeConfig.baseUrl)
        .replace('https://capcito.monto.ai', BeConfig.baseUrl)
    }

    // We don't have locale, so this is a quick fix for now.
    const cleanPath = asPath.split('?')[0]
    const culture = (cleanPath.split('/')[1] ? cleanPath.split('/')[1] : 'sv')
    /*
     * Normalizing the path
     * We'll append the current culture
     */
    const path = link.cached_url
      .replace('/en/', '')
      .replace('/sv/', '')
      .replace('en/', '')
      .replace('sv/', '')

    if (path.startsWith('sv')) {
      path.replace('sv', '')
    } else if (path.startsWith('en')) {
      path.replace('en', '')
    }

    const queryString = Object.keys(query)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
      .join('&')

    if (queryString) {
      return `/${culture}/${path}?${queryString}`
    }

    return `/${culture}/${path}`
  }

  return {
    isExternalUrl,
    linkToHref,
    isAnchor,
    linkIs,
    link,
    tel,
  }
}
