import { InvoicePeriodSlider } from '~/components/Slider/InvoicePeriodSlider'
import { AmountRangeSlider } from 'components/Slider/AmountRangeSlider'
import { BaseCalculator } from 'components/Storyblok/Partials/Hero/BaseCalculator'
import { FormLabel } from 'components/Scl/Typography/FormLabel'
import { Heading } from 'components/Scl/Typography/Heading'
import { FunctionComponent, useState } from 'react'
import { Factoring } from '@capcito/ui-core-logic'
import { Blok, BlokProps } from '@storyblok/react'
import { formatMoney } from '@capcito/ui-money'
import { useRouter } from 'next/router'

export interface Props extends Blok {
  text: string
  disclaimer: any
  title: any
  cta: any
  amountLabel: any
  periodLabel: any
  periodUnit: any
  totalLabel: any
  days: string
}

export const SellInvoiceCalculator: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const { locale } = useRouter()

  const culture = locale ?? 'sv'

  const [amount, setAmount] = useState(100000)
  const [period, setPeriod] = useState(14)

  const formattedAmount = formatMoney(amount, 'SEK', culture)

  function interest() {
    switch (period) {
      case 10:
        return 0.77
      case 14:
        return 0.81
      case 30:
        return 1
      case 45:
        return 1.5
      case 60:
        return 1.85
      case 90:
        return 2.55
    }

    throw new Error(`There was no interest defined for ${period} days`)
  }

  const disclaimer = blok.disclaimer.replace('{interest}', interest())

  const result = {
    total: formatMoney(amount - Factoring.fee(amount, (interest() / 100), true), 'SEK', culture)
  }

  return (
    <BaseCalculator
      btn={blok.cta[0]}
      title={blok.title}
      leftCol={<>
        <div className="flex mb-2 xl:mb-4">
          <div className="w-1/2">
            <FormLabel>
              {blok.amountLabel}
            </FormLabel>
          </div>

          <div className="w-1/2 text-right">
            <Heading
              className="text-blue-500"
              size="tiny"
            >
              {formattedAmount}
            </Heading>
          </div>
        </div>

        <AmountRangeSlider value={amount} onInput={setAmount} />
      </>}
      rightCol={<>
        <div className="flex mb-2 xl:mb-4">
          <div className="w-1/2">
            <FormLabel>
              {blok.periodLabel}
            </FormLabel>
          </div>

          <div className="w-1/2 text-right">
            <Heading
              className="text-blue-500"
              size="tiny"
            >
              {`${period} ${blok.periodUnit}`}
            </Heading>
          </div>
        </div>

        <InvoicePeriodSlider value={period} input={setPeriod} />
      </>}
      result={<>
        <Heading
          size={{ base: 'small', lg: 'regular' }}
          className="text-blue-500"
        >
          {result.total}
        </Heading>
        <div className="text-sm font-semibold text-indigo-500 text-opacity-50">
          {blok.totalLabel}
        </div>
      </>}
      footer={<>
        <p className="text-sm font-semibold text-indigo-500 text-opacity-50">
          {disclaimer}
        </p>
      </>}
    />
  )
}
