import { MediaTagline } from '~/components/MediaTagline/MediaTagline'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'

export interface Props extends HasClassName {
  teasers: any[]
}

export const CompactMediaTeaserList: FunctionComponent<Props> = ({ teasers, className }) => {
  return (
    <div className={className}>
      {teasers.map((teaser, index) => (
        <Anchor
          className="block py-4 border-t border-gray-200 group"
          to={teaser.link}
          key={index}
        >
          <MediaTagline
            date="teaser.date"
          />

          <p className="group-hover:underline">
            {teaser.title}
          </p>
        </Anchor>
      ))}
    </div>
  )
}
