import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const WarningTriangleIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M13.0005 14.0001H11.0005V9.00006H13.0005V14.0001ZM13.0005 18.0001H11.0005V16.0001H13.0005V18.0001ZM1.86951 19.499C1.48354 20.1657 1.9646 21.0001 2.73494 21.0001H21.266C22.0364 21.0001 22.5174 20.1657 22.1315 19.499L12.8659 3.49489C12.4807 2.8296 11.5202 2.8296 11.1351 3.49489L1.86951 19.499Z"
      fill="currentColor"
    />
    </svg>
  )
}
