import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const FlexIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M2 22.0001V20.0001C2 20.0001 7 18.0001 12 18.0001C17 18.0001 22 20.0001 22 20.0001V22.0001H2ZM11.3 9.10005C10.1 5.20005 4 6.10005 4 6.10005C4 6.10005 4.2 13.9001 9.9 12.7001C9.5 9.80005 8 9.00005 8 9.00005C10.8 9.00005 11 12.4001 11 12.4001V17.0001C11.3 17.0001 11.7 17.0001 12 17.0001C12.3 17.0001 12.7 17.0001 13 17.0001V12.8001C13 12.8001 13 8.90005 16 7.90005C16 7.90005 14 10.9001 14 12.9001C21 13.6001 21 4.00005 21 4.00005C21 4.00005 12.1 3.00005 11.3 9.10005Z"
      fill="currentColor"
    />
    </svg>
  )
}
