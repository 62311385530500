import { useRouter } from "next/router";

const UTM_TAGS_LS_KEY = '_utmtags'

interface UTM {
  campaign: string|null;
  content: string|null;
  medium: string|null;
  source: string|null;
  term: string|null;
}

export const useUtm = () => {
  const { query } = useRouter()

  const tags = (): UTM => ({
    campaign: query['utm_campaign']?.toString() ?? null,
    content: query['utm_content']?.toString() ?? null,
    medium: query['utm_medium']?.toString() ?? null,
    source: query['utm_source']?.toString() ?? null,
    term: query['utm_term']?.toString() ?? null,
  })

  function readLocalStorage(): UTM|{} {
    const record = localStorage.getItem(UTM_TAGS_LS_KEY)

    if (record) {
      return JSON.parse(record)
    }

    // Temorary solution due to bug in API
    // Should return `null` when possible
    // Make sure to adjust the return type to `UTM|null`
    return {}
  }

  function writeLocalStorage() {
    const hasAnyTag = Object.values(tags())
      .some(tag => tag !== null)

    if (!hasAnyTag) {
      return;
    }

    localStorage.setItem(UTM_TAGS_LS_KEY, JSON.stringify(tags()))
  }

  return {
    writeLocalStorage,
    readLocalStorage,
  }
}
