import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { useToggle } from 'hooks/toggle'

type ReturnType = boolean | undefined

interface ChildrenProps {
  open: ReturnType
  toggle: () => void
}

export interface Props extends HasClassName {
  initialState?: boolean
  children(props: ChildrenProps): JSX.Element
}

export const CollapseProvider: FunctionComponent<Props> = ({ className, initialState = false, children }) : JSX.Element => {
  const { open, toggle } = useToggle(initialState)

  return (
    <div className={className}>
      { children({ open, toggle }) }
    </div>
  )
}
