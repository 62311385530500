import { RegularMediaTeaserList } from 'components/Storyblok/Partials/Teaser/RegularMediaTeaserList'
import { LargeMediaTeaser } from 'components/Storyblok/Partials/Teaser/LargeMediaTeaser'
import { BlogVerticalCta } from 'components/Storyblok/Partials/Blog/BlogVerticalCta'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Props extends Blok {
  teasers: any[]
  reversed: any
  cta: any,
  title: any,
}

export const BlogTeaserSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const largeTeaser = blok.teasers.find(teaser => teaser.size === 'LARGE')
  const regularTeaser = blok.teasers.filter(teaser => teaser.size === 'REGULAR')

  return (
    <SpacingWrapper
      size="xs"
      background="LIGHT_IVORY"
      className={className}
    >
      <Container
        width="xl"
        className="md:mb-16"
      >
        <div
          className={classNames(
            'flex flex-col pt-6 lg:flex-row',
            { 'lg:flex-row-reverse': blok.reversed }
          )}
        >
          <div
            className={ classNames(
              'border-gray-200 lg:w-9/12',
              blok.reversed ? 'lg:border-l lg:pl-8' : 'lg:border-r lg:pr-8'
            )}
          >
            <Anchor
              to={largeTeaser.link}
              className="block"
            >
              <LargeMediaTeaser
                image={largeTeaser.asset.filename}
                preamble={largeTeaser.preamble}
                title={largeTeaser.title}
                date={largeTeaser.date}
                tags={largeTeaser.tags}
              />
            </Anchor>

            <RegularMediaTeaserList
              title={blok.title}
              teasers={regularTeaser}
              className="lg:mr-16"
            />
          </div>

          <div className={classNames('lg:w-3/12', blok.reversed ? 'lg:pr-8' : 'lg:pl-8')}>
            { blok.cta.map((cta:any) => (
              <BlogVerticalCta
                key={cta._uid}
                blok={cta}
              />
            )) }
          </div>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
