import { ProductCard } from 'components/Storyblok/Partials/Product/ProductCard'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Blok, BlokProps, LinkField } from '@storyblok/react'
import { Nudge } from 'components/Storyblok/Partials/Nudge'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { Anchor } from '~/components/Anchor/Anchor'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { useLink } from 'hooks/link'

interface Props extends Blok {
  background: Background
  heading: any
  products: any[]
  ctas: any[]
  nudge: any
}

export const ProductSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const { linkToHref } = useLink()
  const { asPath } = useRouter()

  const checkShouldScroll = (link: LinkField) => {
    if (linkToHref(link) !== asPath) {
      return
    }

    window.scrollTo(0, 0)
  }

  return (
    <SpacingWrapper
      size="md"
      background={blok.background}
    >
      <Container width="2xl">
        <HeadingStoryblok
          className="mb-16 text-center"
          blok={blok.heading[0]}
        />

        <div className="flex flex-col justify-center space-y-6 lg:flex-row lg:space-x-8 lg:space-y-0">
          {blok.products.map(product => (
            <ProductCard
              className="w-full lg:w-1/3"
              blok={product}
              key={product._uid}
            >

              {product.ctas.map((cta: any) => (
                <Anchor
                  dataTrack={cta.trackingId}
                  to={cta.link}
                  key={cta._uid}
                >
                  <Btn
                    onClick={() => checkShouldScroll(cta.link)}
                    theme={cta.theme}
                    block={true}
                  >
                    {cta.text}
                  </Btn>
                </Anchor>
              ))}
            </ProductCard>
          ))}
        </div>

        {blok.nudge.length > 0 && (
          <Nudge
            blok={blok.nudge[0]}
            className="mt-6"
          />
        )}
      </Container>
    </SpacingWrapper>
  )
}
