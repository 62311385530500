import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Lottie } from 'components/Scl/Tools/Animations/Lottie'
import { StepList } from '~/components/Step/StepList'
import { Caption } from 'components/Scl/Typography/Caption'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { Transition } from '@headlessui/react'

export interface Props extends Blok {
  background: any,
  subTitle: any,
  heading: any,
  items: any[],
  interval: any,
  autoplay: boolean,

}

export const CarouselSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const interval = useRef<NodeJS.Timer|null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const current = blok.items[currentIndex]

  const setActiveItem = (index: number) => {
    if (interval.current) {
      clearInterval(interval.current)
    }

    setCurrentIndex(index)
  }

  useEffect(() => {
    if (blok.autoplay && !interval.current) {
      interval.current = setInterval(() => {
        const totalItems = blok.items.length

        setCurrentIndex((prev) => (prev + 1) < totalItems ? prev + 1 : 0)
      }, parseInt(blok.interval))
    }
  }, [])

  return (
    <SpacingWrapper
      size="lg"
      background={blok.background}
    >
      <Container width="2xl">
        <div className="md:text-center">
          <Caption className="mb-2">
            { blok.subTitle }
          </Caption>

          <HeadingStoryblok
            className="mb-8 lg:mb-20"
            blok={blok.heading[0]}
          />
        </div>

        <div className="flex flex-wrap">
          <div className="w-full md:w-1/2">
            <div className="md:max-w-xl">
              <div className="aspect-w-10 aspect-h-7">
                <Transition
                  enterTo="transform translate-y-0 opacity-100"
                  leaveTo="transform -translate-y-12 opacity-0"
                  leaveFrom="transform translate-y-0 opacity-100"
                  enter="transition-all duration-300 ease-out"
                  leave="transition-all duration-200 ease-in"
                  enterFrom="transform translate-y-12 opacity-0"
                  show={true}
                >
                  {current.illustration[0].component === 'CarouselItemAnimation' && (
                    <Lottie
                      path={current.illustration[0].animation.filename.replace('https://a.storyblok.com', 'https://s3.amazonaws.com/a.storyblok.com')}
                      assetsPath={current.illustration[0].assetsPath}
                      autoplay={current.illustration[0].autoplay}
                      loop={current.illustration[0].loop}
                      key={current._uid}
                      className="aspect-w-10 aspect-h-7"
                    />
                  )}

                  { current.illustration[0].component === 'CarouselItemImage' && (
                    <img
                      src={current.illustration[0].asset.filename}
                      alt={current.illustration[0].asset.altText}
                      className="w-full"
                    />
                  )}
                </Transition>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            <StepList
              onClickIndex={setActiveItem}
              current={currentIndex}
              items={blok.items}
              heading-level="h3"
            />
          </div>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
