import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export const QuoteDots: FunctionComponent<HasClassName> = ({ className }) => {
  return (
    <div className={classNames('flex', className)}>
      <div className="w-4 h-4 mr-1 bg-blue-500 rounded-full" />
      <div className="w-4 h-4 bg-blue-500 rounded-full" />
    </div>
  )
}
