import { Btn } from 'components/Scl/Form/Button/Btn'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { HeadingStoryblok } from '../HeadingStoryblok'
import { Anchor } from '~/components/Anchor/Anchor'

export interface Props extends Blok {
  heading: any
  text: any
  cta: any
}

export const Nudge: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <div className="flex flex-wrap items-center p-8 bg-white lg:flex-no-wrap">
      <div className="w-full mb-8 md:w-9/12 md:mb-0">
        <HeadingStoryblok
          className="mb-2"
          blok={blok.heading[0]}
        />

        <div>
          { blok.text }
        </div>
      </div>
      <div className="w-full md:w-3/12">
        <Anchor
          data-track={blok.cta[0].trackingId}
          to={blok.cta[0].link}
        >
          <Btn
            theme={blok.cta[0].theme}
            block={true}
          >
            { blok.cta[0].text }
          </Btn>
        </Anchor>
      </div>
    </div>
  )
}
