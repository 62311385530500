import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Props extends Blok {
  text: any
  title: any
}

export const TextUsps: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <div
      className={ classNames('px-8 py-12 text-center bg-ivory-300', className) }
    >
      <Heading size="small">
        { blok.title }
      </Heading>

      <div className="mt-2 font-bold sm:text-lg">
        { blok.text }
      </div>
    </div>
  )
}
