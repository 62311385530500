import { RegularMediaTeaser } from '~/components/RegularMediaTeaser/RegularMediaTeaser'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'

export interface Props extends HasClassName {
  title: string
  teasers: any[]
}

export const RegularMediaTeaserList: FunctionComponent<Props> = ({ title, teasers, className }) => {
  return (
    <div className={className}>
      {teasers.map((teaser, index) => (
        <div
          className="border-t border-gray-200"
          key={index}
        >
          {index === 0 && (
            <div className="mt-4 -mb-2 text-sm font-bold text-indigo-500 uppercase text-opacity-60">
              {title}
            </div>
          )}

          <Anchor
            to={teaser.link}
            className="block"
          >
            <RegularMediaTeaser
              image={teaser.asset.filename}
              preamble={teaser.preamble}
              title={teaser.title}
              date={teaser.date}
              tags={teaser.tags}
            />
          </Anchor>
        </div>
      ))}
    </div>
  )
}
