import { Container } from 'components/Scl/Layout/Container/Container'
import { IconUsp } from 'components/Storyblok/Partials/IconUsp'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Props extends Blok {
  usps: any
}

export const HeroUspFooter: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <Container
      className={classNames('mt-6', className)}
      width="2xl"
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        { blok.usps.map((usp: any, index: number) => (
          <IconUsp
            blok={usp}
            key={index}
          />
        ))}
      </div>
    </Container>
  )
}
