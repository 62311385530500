import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  text: string
  size: any
  sizeSm: any
  sizeMd: any
  sizeLg: any
  sizeXl: any
  size2xl: any
  tag: any
}

export const HeadingStoryblok: FunctionComponent<BlokProps<Props> & HasClassName> = ({ blok, className }) => {
  const s = {
    base: blok?.size,
    sm: blok?.sizeSm,
    md: blok?.sizeMd,
    lg: blok?.sizeLg,
    xl: blok?.sizeXl,
    '2xl': blok?.size2xl,
  }

  const sizes = Object.fromEntries(
    Object.entries(s)
      .filter(([_, v]) => v)
  )

  if(!blok) {
    return null
  }

  return (
    <Heading
      className={className}
      size={sizes}
      tag={blok.tag}
    >
      {blok.text}
    </Heading>
  )
}
