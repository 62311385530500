import axios from 'axios'
import { useState } from 'react'
import { useUtm } from '~/hooks/utm'
import { ApiConfig } from '~/config/api'

export const useLeads = () => {
  const { readLocalStorage } = useUtm()
  const [leadId, setLeadId] = useState(null)
  const { baseUrl } = ApiConfig

  function createOrUpdateLead(payload: object, meta?: object): Promise<unknown> {
    const data = {
      utm: readLocalStorage(),
      ...payload,
    }

    return new Promise((resolve, reject) => {
        (leadId
          ? axios.put(`${baseUrl}/internal/v1/onboarding/leads/${leadId}`, { data, meta })
          : axios.post(`${baseUrl}/internal/v1/onboarding/leads`, { data, meta })
        )
          .then((response) => {
            setLeadId(response.data.data.id)
            resolve(response.data.data)
          })
          .catch((error) => {
            const response = error.response;
            reject(response)
          })
    })
  }

  return {
    createOrUpdateLead,
    leadId
  }
}
