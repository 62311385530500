import { StepContent } from '~/components/Step/StepContent'
import { StepIndicator } from '~/components/Step/StepIndicator'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Item {
  title: string,
  text: string,
}

export interface Props extends HasClassName {
  items: Item[],
  current: number,
  clickable?: boolean,
  headingLevel?: string,
  onClickIndex: (index: number) => void,
}

export const StepList: FunctionComponent<Props> = (props) => {
  const { items, current, clickable = true, headingLevel, className, onClickIndex } = props

  const getIndicatorNumber = (n: number) => {
    return n.toString().padStart(2, '0')
  }

  const isLast = (items: Item[], index: number) => {
    return (index + 1) === items.length
  }

  return (
    <div>
      { items.map((item, index) => (
      <div
        className={classNames('relative flex', { 'pb-8': !isLast(items, index) })}
        key={index}
      >
        <div
          className="absolute top-0 left-0 z-10 h-full px-px mt-1 ml-5 bg-blue-100"
          v-if="!isLast(items, index)"
        />

        <div className="mt-1 mr-4">
          <StepIndicator
            onClick={() => onClickIndex(index)}
            clickable={clickable}
            active={current === index}
          >
            { getIndicatorNumber(index + 1) }
          </StepIndicator>
        </div>

        <StepContent
          onClick={() => onClickIndex(index)}
          clickable={clickable}
          title={item.title}
          text={item.text}
          heading-level={headingLevel}
        />
      </div>
      )) }
    </div>
  )
}
