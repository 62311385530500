import { BlokProps, Blok, StoryblokComponent } from '@storyblok/react'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  content: any[],
  background : any,
}

export const ContentSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      background={blok.background}
      size="lg"
    >
      <Container
        className="my-4 space-y-12 md:space-y-20"
        width="xl"
      >
        { blok.content.map((partial) => (
          <StoryblokComponent
            key={partial._uid}
            blok={partial}
          />
        ))}
      </Container>
    </SpacingWrapper>
  )
}
