import { useState } from 'react'

export const useToggle = (initialValue = true) => {
  const [ open, setOpen ] = useState<boolean>(initialValue)

  const toggle = () => setOpen((prev) => !prev)

  return {
    toggle,
    open,
  }
}
