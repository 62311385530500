import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'


export interface Props extends Blok {
  text: any
}

export const SectionLongText: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const renderer = {
    heading: (text: string, level: number) =>
      `<h${level} class="mt-12 text-2xl text-center">
        ${text}
      </h${level}>`,

    list: (body: string, ordered: boolean) =>
      `<${ordered ? 'ol' : 'ul'} class="${ordered ? 'list-decimal' : 'list-none'} pl-5 mt-2 space-y-2">
        ${body}
      </${ordered ? 'ol' : 'ul'}>`,

    paragraph: (text: string) =>
      `<p class="mt-4">
        ${text}
      </p>`,
  }

  return (
    <div className={classNames('max-w-2xl mx-auto md:px-8', className)}>
      <MarkdownRenderer
        markdown={blok.text}
        renderer={renderer}
      />
    </div>
  )
}
