import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { IconUsp } from 'components/Storyblok/Partials/IconUsp'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  heading: any
  usps: any
}

export const UspSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      size="md"
      background="IVORY"
    >
      <Container width="2xl">
        <HeadingStoryblok
          className="mb-16 text-center"
          blok={blok.heading[0]}
        />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          { blok.usps.map((usp: any) => (
            <IconUsp
              key={usp._uid}
              blok={usp}
            />
          ))}
        </div>
      </Container>
    </SpacingWrapper>
  )
}
