import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { QuoteDots } from '~/components/QuoteDots/QuoteDots'
import { Heading } from 'components/Scl/Typography/Heading'
import { Blok, BlokProps } from '@storyblok/react'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import Image from 'next/image'

interface Props extends Blok {
  background: Background
  heading: any
  text: any
  quotes: any[]
}

export const QuoteSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <SpacingWrapper
      background={blok.background}
      size="sm"
    >
      <Container>
        <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-16">
          {blok.quotes.map(quote => (
            <div
              className="flex flex-col justify-between"
              key={quote._uid}
            >
              <div>
                <QuoteDots />

                <Heading
                  className="max-w-md my-6"
                >
                  {quote.text}
                </Heading>
              </div>

              <div className="flex items-center">
                <img
                  className="w-16 h-16 mr-4 rounded-full"
                  src={quote.authorAsset.filename}
                  alt={quote.authorAsset.alt}
                />

                <div className="text-xs font-bold leading-none uppercase">
                  <MarkdownRenderer
                    markdown={quote.authorText}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </SpacingWrapper>
  )
}
