import { TagManagerArgs } from 'react-gtm-module-custom-domain';

export const GTM_AUTH = process.env.NEXT_PUBLIC_GTM_AUTH || 'amNiX112e6-A85gsYivDfQ'
export const GTM_PREVIEW = process.env.NEXT_PUBLIC_GTM_PREVIEW || 'env-2'
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || 'GTM-NDF3FDD'
export const ENABLED = process.env.NEXT_PUBLIC_GTM_ENABLED || true
export const GTM_URL = process.env.NEXT_PUBLIC_GTM_URL || 'https://gtm.capcito.com/gtm.js'

export const Config: TagManagerArgs = {
  preview: GTM_PREVIEW,
  auth: GTM_AUTH,
  gtmId: GTM_ID,
  customURL: GTM_URL,
}
