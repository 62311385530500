import { FunctionComponent, ReactNode } from 'react'
// docs: https://www.npmjs.com/package/react-animate-height
import AnimateHeight from 'react-animate-height';

export interface Props {
  children: ReactNode
  duration?: number
  height: 0 | 'auto'
  animateHeightId: string
}

export const HeightTransition: FunctionComponent<Props> = ({ children, duration = 200, height = 'auto', animateHeightId }) => {
  return (
    <AnimateHeight
      id={animateHeightId}
      duration={duration}
      height={height}
      className="w-full"
    >
      {children}
    </AnimateHeight>
  )
}
