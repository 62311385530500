import { HasClassName } from 'models/HasClassName'
import * as Icons from 'components/Scl/Icon'
import { FunctionComponent } from 'react'

export interface Props extends HasClassName {
  icon: string
}

export const Icon: FunctionComponent<Props> = ({ className, icon }) => {
  const Icon = (Icons as any)[icon]
  return (
    <Icon className={ className }/>
  )
}
