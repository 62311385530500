import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'
import Image from 'next/image'
import { HeadingStoryblok } from '../HeadingStoryblok'

export interface Props extends Blok {
  reversed: boolean,
  heading: any,
  text: any,
  asset: any,
}

export const SectionImageText: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <div
      className={classNames(
        'flex flex-col md:flex-row md:space-y-0 md:items-center',
        {'md:flex-row-reverse': blok.reversed},
        className,
    )}>
      <div className="hidden w-1/12 md:block" />

      <div className="w-full md:w-5/12">
        <img
          className="w-full rounded md:max-w-lg"
          src={blok.asset.filename}
          alt={blok.asset.altText}
        />
      </div>

      <div className="hidden w-1/12 md:block" />

      <div className="w-full md:w-4/12">
        <HeadingStoryblok
          blok={blok.heading[0]}
          className="mt-6 mb-4 md:mt-0"
        />

        <MarkdownRenderer
          markdown={blok.text}
        />
      </div>
    </div>
  )
}
