import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const MessagesWomanBubble: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 24 24"
      onClick={onClick}
      fill="none"
    >
      <path
        d="M11.174 17.385C11.076 17.3525 11.0073 17.264 11 17.161V16.483C12.0596 16.5379 13.1108 16.2701 14.015 15.715C14.1391 15.6406 14.225 15.5161 14.2503 15.3736C14.2756 15.2311 14.2379 15.0846 14.147 14.972C13.726 14.472 13.434 13.818 13.305 11.912C13.059 8.277 10.607 7 8.5 7C6.393 7 3.943 8.277 3.7 11.912C3.571 13.812 3.279 14.475 2.858 14.972C2.76699 15.0845 2.72916 15.2309 2.75428 15.3733C2.7794 15.5158 2.86501 15.6404 2.989 15.715C3.8924 16.2684 4.94189 16.5361 6 16.483V17.162C5.99297 17.2651 5.92417 17.3537 5.826 17.386C2.958 18.448 1.392 19.207 0.792 20.408C0.311649 21.3708 0.0416707 22.4248 0 23.5C0 23.7761 0.223858 24 0.5 24H7.4C7.48359 24 7.56167 23.9583 7.60809 23.8888C7.6545 23.8193 7.6631 23.7312 7.631 23.654L6.14 20.049C6.09913 19.9527 6.12313 19.841 6.2 19.77C6.2772 19.6989 6.39049 19.6833 6.484 19.731C7.80221 20.4097 9.36407 20.4234 10.694 19.768C10.7886 19.7217 10.9021 19.7396 10.9778 19.8128C11.0535 19.8861 11.0752 19.9989 11.032 20.095L9.441 23.648C9.40694 23.7255 9.41423 23.8149 9.46039 23.8859C9.50655 23.9568 9.58536 23.9997 9.67 24H16.5C16.7761 24 17 23.7761 17 23.5C16.9584 22.4251 16.6884 21.3714 16.208 20.409C15.607 19.2 14.042 18.446 11.174 17.385Z"
        fill="currentColor"
      />
      <path
        d="M22 0H9C7.89543 0 7 0.89543 7 2V5C7 5.55228 7.44772 6 8 6C8.55228 6 9 5.55228 9 5V2.25C9 2.11193 9.11193 2 9.25 2H21.75C21.8881 2 22 2.11193 22 2.25V8.75C22 8.88807 21.8881 9 21.75 9H20.5C20.2348 9.00006 19.9805 9.10545 19.793 9.293L17.927 11.159C17.8555 11.2307 17.7478 11.2522 17.6543 11.2134C17.5607 11.1746 17.4998 11.0833 17.5 10.982V10C17.5 9.44771 17.0523 9 16.5 9H15C14.4477 9 14 9.44771 14 10C14 10.5523 14.4477 11 15 11H15.5V14C15.5001 14.4044 15.7437 14.7689 16.1173 14.9237C16.491 15.0784 16.921 14.9929 17.207 14.707L20.914 11H22C23.1046 11 24 10.1046 24 9V2C24 0.89543 23.1046 0 22 0Z"
        fill="currentColor"
      />
      <path
        d="M12.502 6.75C13.0543 6.75 13.502 6.30228 13.502 5.75C13.502 5.19772 13.0543 4.75 12.502 4.75C11.9497 4.75 11.502 5.19772 11.502 5.75C11.502 6.30228 11.9497 6.75 12.502 6.75Z"
        fill="currentColor"
      />
      <path
        d="M15.752 6.75C16.3043 6.75 16.752 6.30228 16.752 5.75C16.752 5.19772 16.3043 4.75 15.752 4.75C15.1997 4.75 14.752 5.19772 14.752 5.75C14.752 6.30228 15.1997 6.75 15.752 6.75Z"
        fill="currentColor"
      />
      <path
        d="M19.003 6.75C19.5553 6.75 20.003 6.30228 20.003 5.75C20.003 5.19772 19.5553 4.75 19.003 4.75C18.4507 4.75 18.003 5.19772 18.003 5.75C18.003 6.30228 18.4507 6.75 19.003 6.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
