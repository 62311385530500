import { MediaTagline } from '~/components/MediaTagline/MediaTagline'
import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Props extends HasClassName {
  title: string
  preamble: string
  date: string
  tags: string
  image: string
  author?: string
}

export const RegularMediaTeaser: FunctionComponent<Props> = ({ title, preamble, date, tags, image, author, className }) => {
  return (
    <div className={classNames('flex flex-row flex-grow min-w-0 py-6 group', className)}>
      <div className="flex-grow min-w-0">
        <Heading
          size={{ base: 'tiny', lg: 'regular' }}
          className="mb-2 group-hover:underline"
        >
          {title}
        </Heading>

        <div className="hidden text-lg text-indigo-500 text-opacity-60 md:flex">
          <div className="truncate">
            {preamble}
          </div>
        </div>
        <div className="flex flex-wrap mt-4 text-xs text-indigo-500 text-opacity-60">
          <MediaTagline
            date={date}
            name={author}
            tags={tags}
          />
        </div>
      </div>
      <div>
        <div
          style={{ backgroundImage: `url(${image})` }}
          className="w-24 h-full ml-8 bg-top bg-no-repeat bg-cover md:w-40"
        />
      </div>
    </div>
  )
}
