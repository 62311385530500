import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Sticker } from 'components/Scl/Elements/Badge/Sticker'
import { Children, FunctionComponent, ReactNode } from 'react'
import { Blok, BlokProps } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import classNames from 'classnames'

export interface Props extends Blok {
  text: string
  sticker: any
  asset: any
  subHeading: any
  heading: any
}

interface Children extends HasClassName {
  children?: ReactNode
}

export const ProductCard: FunctionComponent<BlokProps<Props> & Children> = ({ blok, children, className }) => {
  const renderer = {
    list: (body: string) => `<ul class="mt-5 space-y-4 font-bold list-check">${body}</ul>`,
  }

  return (
    <div className={classNames('flex flex-col overflow-hidden bg-white rounded shadow', className)}>
      <div className="relative flex flex-col justify-end h-64 p-8 transition-all duration-200 bg-ivory-500">
        <img
          className="absolute bottom-0 right-0 z-0 h-full transition-opacity duration-200"
          src={blok.asset.filename}
          alt={blok.asset.alt}
        />

        {blok.sticker && (
          <Sticker className="absolute top-0 right-0 m-4 transform bg-blue-500 text-ivory-300 rotate-15">
            <div className="font-serif text-lg font-semibold leading-4">
              {blok.sticker}
            </div>
          </Sticker>
        )}

        <div className="relative">
          <HeadingStoryblok
            className="mb-2 text-indigo-500"
            blok={blok.heading[0]}
          />

          <div className="font-bold text-blue-500">
            {blok.subHeading}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-between flex-grow p-8 text-indigo-500 bg-white">
        <div className="mb-3">
          <MarkdownRenderer
            markdown={blok.text}
            renderer={renderer}
          />
        </div>

        {children && (
          <div className="flex flex-col mt-8 space-y-2">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}
