import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { HasClassName } from 'models/HasClassName'
import { PropsWithChildren } from 'react'

export interface Props extends HasClassName {
  active: boolean,
  clickable?: boolean,
  onClick?: () => void,
}

export const StepIndicator: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const { active, clickable = true, onClick, className, children } = props

  return (
    <div
      onClick={onClick}
      className={classNames({
        'font-serif text-xl rounded-full w-10 leading-6 text-center relative z-20 transition-colors duration-200': true,
        'bg-blue-100 text-blue-500': !active,
        'bg-blue-500 text-white': active,
        'cursor-pointer': clickable,
      }, className)}
    >
      { children }
    </div>
  )
}
