import { FunctionComponent, MutableRefObject, useEffect, useRef } from 'react'
import { HasClassName } from 'models/HasClassName'
import * as noUiSlider from 'nouislider'

export interface Props extends HasClassName {
  options: any
  input: any
}

export const RangeSlider: FunctionComponent<Props> = ({ options, input, className }) => {
  const slider = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (slider.current?.classList.contains('noUi-target')) {
      return
    }

    noUiSlider
      .create(slider.current as HTMLDivElement, options)
      .on('update', (values) => input(values))
  }, [])

  return (
    <div className={className} ref={slider} />
  )
}
