import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

export const AddDocumentIcon: FunctionComponent<HasClassName> = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 14H19V17H22V19H19V22H17V19H14V17H17V14ZM12 17V15H7V17H12ZM17 11H7V13H14.69C13.07 14.07 12 15.91 12 18C12 19.09 12.29 20.12 12.8 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V12.8C20.12 12.29 19.09 12 18 12L17 12.08V11ZM17 9V7H7V9H17Z"
        fill="currentColor"
      />
    </svg>
  )
}
