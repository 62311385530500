import { useRouter } from 'next/router'

export const useDate = () => {
  const { locale } = useRouter()

  const getMonths = (): string[] => {
    const culture = locale ?? 'sv'

    switch (culture) {
      case 'sv':
        return ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']
      case 'en':
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    }

    throw new Error(`The given culture [${culture}] is not valid.`)
  }

  const toDayMonth = (input: string) => {
    const date = new Date(input)
    const months = getMonths()

    return `${date.getDate()} ${months[date.getMonth()]}`
  }

  const toDayMonthYear = (input: string) => {
    const date = new Date(input)
    const months = getMonths()

    return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`
  }

  return {
    toDayMonthYear,
    toDayMonth
  }
}
