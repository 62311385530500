import { RangeSlider } from 'components/Scl/Form/Field/RangeSlider'
import { HasClassName } from 'models/HasClassName'
import { range } from '@capcito/ui-utils'
import { FunctionComponent } from 'react'
import { useRange } from 'hooks/range'
import * as noUiSlider from 'nouislider'

export interface Props extends HasClassName {
  onInput: any
  value: any
}

export const AmountRangeSlider: FunctionComponent<Props> = ({ onInput, value, className }) => {
  const { asStepValues } = useRange()

  const options: noUiSlider.Options = {
    range: asStepValues([
      ...range(10_000, 50_000, 5_000),
      ...range(60_000, 100_000, 10_000),
      ...range(125_000, 200_000, 25_000),
      ...range(250_000, 500_000, 50_000),
      ...range(600_000, 1_000_000, 100_000),
      ...range(1_250_000, 2_000_000, 250_000),
      ...range(2_500_000, 5_000_000, 500_000),
      ...range(10_000_000, 15_000_000, 5_000_000),
    ]),
    start: Number(value),
    connect: 'lower',
    snap: true,
  }

  const onInputHandler = (event: string[]) => {
    const value = parseInt(event[0])
    onInput(value)
  }

  return (
    <RangeSlider
      input={onInputHandler}
      className={className}
      options={options}
    />
  )
}
