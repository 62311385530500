import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Blok, BlokProps, StoryblokComponent } from '@storyblok/react'
import { Container } from 'components/Scl/Layout/Container/Container'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { ChevronRightIcon } from '../Scl/Icon/ChevronRightIcon'

export interface Props extends Blok {
  text: string,
  breadcrumbs: any,
  heading: any,
  ctas: any,
  disclaimer: any,
  asset: any,
  footer: any
}

export const HeroSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const renderer = {
    list: (body: string) => `<ul class="mt-4 space-y-2 font-bold list-check">${body}</ul>`,
  }

  return (
    <header className="pb-16 lg:pb-20 bg-ivory-500">
      <Container
        className="relative"
        width="2xl"
      >
        { blok.breadcrumbs.length > 0 && (
          <div
            className="flex py-6 lg:ml-32"
          >
            { blok.breadcrumbs.map((breadcrumb: any, index: number) => (
              <Anchor
                className="flex items-center"
                to={breadcrumb.link}
                key={index}
              >
                <div
                  className={classNames(
                    'text-sm font-semibold',
                    [index !== (blok.breadcrumbs.length - 1)
                        ? 'text-blue-500 hover:underline'
                        : 'text-opacity-50 text-indigo-500 cursor-default']
                  )}
                >
                  { breadcrumb.text }
                </div>

                { index < (blok.breadcrumbs.length - 1) && (
                  <ChevronRightIcon
                    className="w-6 h-6 mx-1 text-indigo-300"
                  />
                )}
              </Anchor>
            ))}
          </div>
        )}

        <div className="flex flex-wrap lg:px-20">
          <div className="pt-16 mb-3 md:w-1/2 md:mb-0 lg:pt-26">
            <HeadingStoryblok
              blok={blok.heading[0]}
              className="mb-5"
            />

            <MarkdownRenderer
              className="max-w-lg mb-6 text-lg leading-snug lg:mb-8"
              markdown={blok.text}
              renderer={renderer}
            />

            <div className="flex flex-wrap space-y-2 md:items-center md:space-y-0 md:space-x-2 md:flex-no-wrap">
              { blok.ctas.map((cta: any) => (
                <Anchor
                  className="flex-none w-full md:w-auto"
                  data-track={cta.trackingId}
                  key={cta._uid}
                  to={cta.link}
                >
                  <Btn
                    className="w-full md:w-auto"
                    theme={cta.theme}
                  >
                    { cta.text }
                  </Btn>
                </Anchor>
              ))}
            </div>

            { blok.disclaimer && (
              <small className="block mt-5 text-sm opacity-60">
                { blok.disclaimer }
              </small>
            )}
          </div>

          <div className="hidden md:block md:w-1/2">
            <img
              src={blok.asset.filename}
              alt={blok.asset.alt}
              className="w-full"
            />
          </div>
        </div>
      </Container>

      { blok.footer.length > 0 &&
        blok.footer.map((footer: any) => (
          <StoryblokComponent blok={footer} key={footer._uid} />
        ))}
    </header>
  )
}
