import { FunctionComponent, useState, useEffect, ButtonHTMLAttributes, ReactNode } from 'react'
import { HasClassName } from 'models/HasClassName'
import classNames from 'classnames'

export type Theme = 'primary' | 'secondary' | 'outline-white' | 'secondary-white' | 'danger' | 'link'

export interface Props extends HasClassName {
  size?: 'small' | 'regular'
  theme?: Theme
  block?: boolean
  loading?: boolean
  children?: ReactNode
}

function styling ({ disabled, theme, loading, size, block }: { disabled: boolean, theme: Theme, loading: boolean, size: 'small' | 'regular', block: boolean }) {
  const classes = ['relative', 'rounded-full', 'border', 'font-bold', 'leading-none', 'focus:outline-none']

    if (disabled) {
      classes.push('opacity-50', 'cursor-not-allowed')
    }

    if (theme === 'primary') {
      classes.push('bg-blue-500 border-blue-500 text-white')

      if (!disabled) {
        classes.push('hover:border-blue-700 hover:bg-blue-700')
      }

      if (loading) {
        classes.push('bg-blue-700 border-blue-700')
      }
    } else if (theme === 'secondary') {
      classes.push('border-indigo-200 text-blue-500')

      if (!disabled) {
        classes.push('hover:border-blue-700 hover:bg-blue-700 hover:text-white')
      }
    } else if (theme === 'secondary-white') {
      classes.push('border-indigo-200 text-blue-500 bg-white')

      if (!disabled) {
        classes.push('hover:border-blue-700 hover:bg-blue-700 hover:text-white')
      }
    } else if (theme === 'outline-white') {
      classes.push('border-white hover:border-gray-300 hover:text-white text-white')
    } else if (theme === 'link') {
      classes.push('text-blue-500 hover:text-blue-700 border-transparent hover:underline text-center')
    } else if (theme === 'danger') {
      classes.push('text-white border-red-500 bg-red-500')
    }

    if (size === 'regular') {
      classes.push('py-4 px-6 text-sm')
    } else if (size === 'small') {
      classes.push('py-3 px-4 text-xs')
    }

    if (block) {
      classes.push('w-full')
    }

  return classes
}

export const Btn: FunctionComponent<Props & ButtonHTMLAttributes<HTMLButtonElement>> = (props) => {
  const { children, className, size = 'regular', type = 'button', theme = 'primary', block = false, disabled = false, loading = false, onClick } = props

  const [current, setCurrent] = useState<number>(0)
  const [classList, setClassList] = useState<string>(styling({ disabled, theme, loading, size, block }).join(' '))
  const dots = [0, 1, 2]

  useEffect(() => {
    const classes = styling({ disabled, theme, loading, size, block })

    setClassList(classes.join(' '))
  }, [disabled, loading, theme, size, block])

  function getNextStep() {
    return current !== dots.length - 1
      ? setCurrent((prev) => prev + 1)
      : setCurrent(0)
  }

  useEffect(() => {
    const intervalId = setInterval(() => getNextStep(), 300)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <button
      disabled={disabled || loading}
      className={classNames(classList, className)}
      onClick={onClick}
      type={type}
    >
      <div className={classNames({ 'invisible': loading })}>
        {children}
      </div>

      {loading &&
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
          <div className="flex space-x-1">
            {
              dots.map(dot => (
                <div
                  className={classNames("w-2 h-2 transition-transform duration-150 transform bg-white rounded-full", {
                    'scale-125': current === dot
                  })}
                  key={dot}
                />
              ))
            }
          </div>
        </div>
      }
    </button>
  )
}
