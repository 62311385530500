import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  items: any[],
  text: any,
}

export const DownloadableMediaSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      background={blok.background}
      size="md"
    >
      <Container width="md">
        <div className="max-w-md mx-auto mb-16 text-center">
          <HeadingStoryblok
            blok={blok.heading[0]}
            className="mb-5"
          />

          <MarkdownRenderer
            markdown={blok.text}
            className="text-lg"
          />
        </div>

        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
          {blok.items.map((media: any, index: number) => (
            <a
              className="block overflow-hidden rounded-md shadow"
              href={media.asset.filename}
              target="_blank"
              key={index}
              rel="noreferrer"
            >
              <div
                style={{ backgroundImage: `url(${media.preview.filename})` }}
                className="bg-no-repeat bg-cover aspect-w-1 aspect-h-1"
              />

              <div className="p-6 text-center bg-white">
                <div className="mb-2 text-lg font-semibold leading-none">
                  { media.title }
                </div>
                <div className="text-xs leading-none">
                  { media.text }
                </div>
              </div>
            </a>
          ))}
        </div>
      </Container>
    </SpacingWrapper>
  )
}
