import { FunctionComponent, ReactNode } from 'react'
import { Transition } from '@headlessui/react'

export interface Props {
  appear?: boolean,
  children: ReactNode,
}

export const FadeScaleTransition: FunctionComponent<Props> = ({ children, appear = true }) => {
  return (
    <Transition
      enterTo="opacity-100 transform translate-y-0 scale-100"
      leaveTo="opacity-0 transform translate-y-4 scale-95"
      leaveFrom="opacity-100 transform translate-y-0 scale-100"
      enter="transition-all duration-300 ease-out"
      leave="transition-all duration-200 ease-in"
      enterFrom="transform scale-95 translate-y-4 opacity-0"
      show={appear}
    >
      { children }
    </Transition>
  )
}
