import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const FileDocumentMultipleIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      fill="currentColor"
      d="M4 4V22H20V24H4C2.9 24 2 23.1 2 22V4H4M15 7H20.5L15 1.5V7M8 0H16L22 6V18C22 19.11 21.11 20 20 20H8C6.89 20 6 19.1 6 18V2C6 .89 6.89 0 8 0M17 16V14H8V16H17M20 12V10H8V12H20Z"
    />
    </svg>
  )
}
