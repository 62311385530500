import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { HeadingStoryblok } from '../HeadingStoryblok'

export interface Props extends Blok {
  heading: any
  intro: any
}

export const SectionIntro: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const heading = blok.heading[0]

  return (
    <div className={classNames('text-center', className)}>
      <HeadingStoryblok
        className="max-w-3xl mx-auto mt-8"
        blok={heading}
      />

      <p
        className="max-w-2xl mx-auto mt-4"
        v-if="blok.intro"
      >
        { blok.intro }
      </p>
    </div>
  )
}
