import { FunctionComponent } from 'react'
import { TagType } from 'enums/TagType'
import { HasClassName } from 'models/HasClassName'
import { PropsWithChildren } from 'react'
import classNames from 'classnames'

export interface Props extends HasClassName {
  tag?: TagType,
}

export const Caption: FunctionComponent<PropsWithChildren<Props>> = (props) => {
  const { tag: Tag = 'div', className, children } = props

  return (
    <Tag className={classNames('text-sm font-bold leading-none uppercase', className)}>
      { children }
    </Tag>
  )
}
