import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const QuoteIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      fill="currentColor"
      d="M14,17H17L19,13V7H13V13H16M6,17H9L11,13V7H5V13H8L6,17Z"
    />
    </svg>
  )
}
