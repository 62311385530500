import { FunctionComponent, ReactHTMLElement, ReactNode } from 'react'
import { HasClassName } from 'models/HasClassName'
import { useLink } from 'hooks/link'
import NextLink from 'next/link'

export interface Props {
  children: ReactNode
  dataTrack?: any
  to: any,
  target?: string
}

export const Anchor: FunctionComponent<Props & HasClassName> = ({ className, children, dataTrack, to, target }) => {
  const { linkToHref, isExternalUrl, isAnchor } = useLink()

  const location = to
  const link = linkToHref(location)

  if (isExternalUrl(location) || isAnchor(location)) {
    return <a className={className} href={link} data-track={dataTrack}>{children}</a>
  }

  return (
    <NextLink href={link}>
      <a className={className} data-track={dataTrack} target={target}>{children}</a>
    </NextLink>
  )
}
