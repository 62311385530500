import { Container } from 'components/Scl/Layout/Container/Container'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent, ReactNode } from 'react'
import { useLink } from 'hooks/link'
import classNames from 'classnames'

export interface Props extends HasClassName {
  title: string
  btn: Record<any, any>
  leftCol: ReactNode
  rightCol: ReactNode
  result: ReactNode
  footer: ReactNode
}

export const BaseCalculator: FunctionComponent<Props> = ({ title, btn, leftCol, rightCol, result, footer, className }) => {
  return (
    <Container
      className={classNames('mt-6 mb-12', className)}
      width="2xl"
    >
      <div className="lg:px-20">
        <div className="bg-white rounded-lg shadow-xl">
          <div className="px-4 py-6 lg:p-6 md:border-b md:border-gray-200">
            <div className="flex flex-wrap">
              <div className="w-full md:w-2/3 md:pr-6">
                <div className="mb-5 text-lg font-bold leading-none md:mt-2">
                  {title}
                </div>

                <div className="flex flex-wrap mb-4 md:mb-0">
                  <div className="w-full mb-6 xl:w-1/2 xl:pr-6 xl:mb-0">
                    {leftCol}
                  </div>

                  <div className="w-full xl:w-1/2 xl:pl-6">
                    {rightCol}
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/3 md:pl-6">
                <div className="flex flex-col justify-center h-full p-6 text-center bg-blue-500 rounded-lg bg-opacity-10">
                  {result}
                </div>
              </div>
            </div>
          </div>

          <div className="p-4 pt-0 md:pt-4">
            <div className="md:flex md:flex-row">
              <div className="pb-6 md:w-2/3 md:pr-6 md:pb-0">
                { footer }
              </div>
              <div className="md:w-1/3 md:pl-6">
                <Anchor
                  dataTrack={btn.trackingId}
                  to={btn.link}
                >
                  <Btn
                    block
                    size="small"
                  >
                    {btn.text}
                  </Btn>
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}
