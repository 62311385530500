import { HeightTransition } from '~/components/Scl/Tools/Transitions/HeightTransition'
import { CollapseProvider } from '~/components/Scl/Tools/Collapse/CollapseProvider'
import { FadeTransition } from '~/components/Scl/Tools/Transitions/FadeTransition'
import { Container } from '~/components/Scl/Layout/Container/Container'
import { ChevronDownIcon } from '~/components/Scl/Icon/ChevronDownIcon'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { CapcitoLogo } from '~/components/Scl/Logo/CapcitoLogo'
import { PhoneIcon } from '~/components/Scl/Icon/PhoneIcon'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { Btn } from '~/components/Scl/Form/Button/Btn'
import { Anchor } from '~/components/Anchor/Anchor'
import { Icon } from '~/components/Icon/Icon'
import { Blok } from '@storyblok/react'
import { useRouter } from 'next/router'
import { useLink } from '~/hooks/link'
import { useMenu } from '~/hooks/menu'
import classNames from 'classnames'
import NextLink from 'next/link'

export interface Props extends Blok {
  phone: any
  login: any
  items: any[]
  register: any
}


export const GlobalNavigation: FunctionComponent = () => {
  const { navigation } = useGlobalContext()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const header = useRef<HTMLElement>(null)
  const [height, setHeight] = useState<number>(0)
  const [dropdownHeight, setDropdownHeight] = useState<0 | 'auto'>(0)

  const { link } = useLink()
  const { scrolled } = useMenu(header)
  const { asPath } = useRouter()

  const handleToggle = () => {
    setIsOpen((prev) => !prev)
  }

  const calculate = () => {
    setHeight(window.innerHeight - (header.current?.clientHeight ?? 0))
  }

  useEffect(() => {
    setIsOpen(false)
  }, [asPath])


  useEffect(() => {
    window.addEventListener('resize', () => calculate())
    calculate()

    return () => removeEventListener('resize', () => calculate())
  }, [])

  if (!navigation) {
    return null
  }

  return (
    <nav
      className={classNames('fixed top-0 left-0 z-30 w-full transition-shadow duration-300', { 'shadow': scrolled })}
      ref={header}
    >
      <div className="h-10 text-white bg-indigo-500 xl:hidden">
        <Container
          className="flex items-center justify-between h-full"
          width="2xl"
        >
          <div className="flex items-center">
            <PhoneIcon className="w-4 h-4 mr-2" />

            <a
              className="text-sm font-semibold leading-none hover:underline"
              href={`tel:${navigation.phone}`}
              data-track="Navigation/Phone"
            >
              {navigation.phone}
            </a>
          </div>

          <div>
            <Anchor
              className="text-sm font-semibold leading-none hover:underline"
              data-track="Navigation/Login"
              to={navigation.login[0].link}
            >
              {navigation.login[0].text}
            </Anchor>
          </div>
        </Container>
      </div>

      <div
        className={classNames('relative h-16 transition-colors duration-300 bg-white lg:h-22', { 'bg-opacity-100': scrolled, 'bg-opacity-0': !scrolled })}
      >
        <Container
          className="flex flex-wrap items-center justify-between h-full"
          width="2xl"
        >
          <div className="flex items-center">
            <div className="w-32">
              <NextLink
                data-track="Navigation/Logo"
                href={link('/')}
              >
                <a>
                  <CapcitoLogo svgClass="w-20" />
                </a>
              </NextLink>
            </div>

            <div className="hidden space-x-6 lg:flex">
              {navigation.items.map((item, index) => (
                <div
                  className="text-indigo-500 cursor-pointer hover:text-blue-500"
                  key={index}
                >
                  {item.component === 'GlobalNavigationItem' && (
                    <Anchor
                      to={item.link}
                      className="block"
                    >
                      {item.text}
                    </Anchor>
                  )}

                  {item.component === 'GlobalNavigationDropdownItem' && (
                    <div className="relative pb-4 -mb-4 group">
                      <div className="flex items-center">
                        <div className="pr-2">
                          {item.text}
                        </div>
                        <ChevronDownIcon
                          className="w-4 h-4 transition-transform duration-100 group-hover:transform group-hover:rotate-180"
                        />
                      </div>
                      <div className="absolute top-0 left-0 z-30 w-64 p-4 mt-8 transition-all duration-200 ease-in transform scale-95 translate-y-4 bg-white border border-gray-100 rounded shadow opacity-0 pointer-events-none group-hover:block group-hover:opacity-100 group-hover:transform group-hover:translate-y-0 group-hover:scale-100 group-hover:pointer-events-auto">
                        {item.items.map((child: any, i: number) => (
                          <Anchor
                            className="flex items-center w-full px-3 py-4 rounded dropdown-item hover:text-blue-500 hover:bg-blue-100 hover:cursor-default"
                            to={child.link}
                            key={i}
                          >
                            <Icon
                              className="w-6 h-6 mr-2"
                              icon={child.icon}
                            />

                            <div className="leading-none">
                              {child.text}
                            </div>
                          </Anchor>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="items-center hidden space-x-8 lg:flex">
            <a
              className="hidden hover:text-blue-500 xl:flex"
              data-track="Navigation/Phone"
              href={`tel:${navigation.phone}`}
            >
              <PhoneIcon className="w-5 h-5 mr-2" />

              <span>{navigation.phone}</span>
            </a>

            <Anchor
              className="hidden text-blue-500 hover:underline xl:block"
              data-track="Navigation/Login"
              to={navigation.login[0].link}
            >
              {navigation.login[0].text}
            </Anchor>

            <Anchor
              data-track="Navigation/GetStarted"
              to={navigation.register[0].link}
            >
              <Btn
                theme="primary"
                type="button"
              >
                {navigation.register[0].text}
              </Btn>
            </Anchor>
          </div>

          <div className="py-3 cursor-pointer lg:hidden" onClick={handleToggle}>
            <div className={classNames('w-8 py-px duration-150 transform bg-black', { 'rotate-45': isOpen })} />

            <div className={classNames('w-8 py-px duration-150 transform bg-black', { '-rotate-45 -mt-0.5': isOpen, 'mt-2': !isOpen })} />
          </div>
        </Container>

        <div className={classNames('absolute bottom-0 left-0 w-full py-px transition-opacity duration-300 bg-indigo-500', { 'opacity-10': scrolled, 'opacity-0': !scrolled })} />

        <FadeTransition>
          {isOpen && (
            <div
              className="absolute top-0 left-0 w-full pb-8 mt-16 overflow-y-scroll bg-white shadow lg:hidden"
              style={{ height: `${height}px` }}
            >
              <Container width="2xl">

                {navigation.items.map((item, index) => (
                  <div className="text-xl" key={index}>
                    {item.component === 'GlobalNavigationItem' && (
                      <Anchor
                        className="flex items-center justify-between py-5"
                        to={item.link}
                      >
                        <div className="w-8/12">
                          {item.text}
                        </div>

                        {item.label && (
                          <div className="w-4/12 text-right">
                            <span className="px-2 py-px text-xs font-semibold text-white bg-blue-500 rounded-lg">
                              {item.label}
                            </span>
                          </div>
                        )}
                      </Anchor>
                    )}

                    {item.component === 'GlobalNavigationDropdownItem' && (
                      <CollapseProvider className="py-5">
                        {({ open, toggle }) => (
                          <div
                            aria-expanded={dropdownHeight !== 0}
                            aria-controls="dropdown-height" // it has to match the id passed to AnimateHeight
                            className="flex flex-wrap items-center"
                            onClick={() => {
                              toggle()
                              setDropdownHeight((prev) => prev === 0 ? 'auto' : 0)
                            }}
                          >
                            <div className={classNames('w-8/12', [open ? 'text-blue-500' : null])}>
                              {item.text}
                            </div>
                            <div className="w-4/12">
                              <ChevronDownIcon className={classNames('w-4 h-4 ml-auto transition-transform duration-100', [open ? 'transform rotate-180' : null])} />
                            </div>

                            <HeightTransition height={dropdownHeight} animateHeightId={'dropdown-height'}>
                              <div className="w-full mt-2">
                                {item.items.map((child: any, i: number) => (
                                  <Anchor
                                    className="flex w-full py-4 text-base border-b border-gray-200"
                                    to={child.link}
                                    key={i}
                                  >
                                    <Icon
                                      icon={child.icon}
                                      className="w-5 h-5 mr-2"
                                    />

                                    {child.text}
                                  </Anchor>
                                ))}
                              </div>
                            </HeightTransition>
                          </div>
                        )}
                      </CollapseProvider>
                    )}
                  </div>
                ))}

                <Anchor
                  data-track="Navigation/GetStarted"
                  to={navigation.register[0].link}
                >
                  <Btn
                    theme="primary"
                    type="button"
                    block
                  >
                    {navigation.register[0].text}
                  </Btn>
                </Anchor>
              </Container>
            </div>
          )}
        </FadeTransition>
      </div>
    </nav>
  )
}
