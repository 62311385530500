import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { TagType } from 'enums/TagType'
import classNames from 'classnames'

export interface Props extends HasClassName {
  title: string,
  text: string,
  clickable?: boolean,
  onClick?: () => void,
  headingLevel?: TagType,
}

export const StepContent: FunctionComponent<Props> = (props) => {
  const { clickable = true, title, text, headingLevel, onClick,  className } = props
  return (
    <div
      onClick={onClick}
      className={classNames({'cursor-pointer': clickable}, className)}
    >
      <Heading
        size="small"
        className="pb-2"
        tag={headingLevel}
      >
        { title }
      </Heading>

      <p className="leading-relaxed">
        { text }
      </p>
    </div>
  )
}
