import { ReactNode, FunctionComponent } from 'react'
import { HasClassName } from 'models/HasClassName'
import { TagType } from 'enums/TagType'

export interface Props extends HasClassName {
  tag?: string
  htmlFor?: string,
  children: ReactNode
}

export const FormLabel: FunctionComponent<Props> = ({ children, className, tag = 'label', ...props}) => {
  const Tag = tag as TagType

  return (
    <Tag
      className="block text-sm font-bold leading-none"
      {...props}
    >
      { children}
    </Tag>
  )
}
