type TranslationNodes = Record<string, ReturnType>
type ReturnType = Record<string, string>

export const translations: TranslationNodes = {
  en: {
    required: 'This field is required',
    email: 'This field must be a valid email',
    ssn: 'This field must be a valid organization number',
  },
  sv: {
    required: 'Detta fält är obligatoriskt',
    email: 'Detta fält måste vara en giltig email',
    ssn: 'Detta fält måste vara ett giltigt organisationsnummer',
  },
}

export function useTranslations(locale: string): ReturnType | null {
  if (locale && locale in translations) {
    return translations[locale]
  }

  return null
}
