import { ShareNetwork } from 'components/Storyblok/Partials/Blog/ShareNetwork'
import { Container } from 'components/Scl/Layout/Container/Container'
import { MediaTagline } from '~/components/MediaTagline/MediaTagline'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { LinkedInIcon } from 'components/Scl/Icon/LinkedInIcon'
import { FacebookIcon } from 'components/Scl/Icon/FacebookIcon'
import { FunctionComponent, useEffect, useState } from 'react'
import { TwitterIcon } from 'components/Scl/Icon/TwitterIcon'
import { Heading } from 'components/Scl/Typography/Heading'
import { StoryblokComponent } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { useDate } from 'hooks/date'
import classNames from 'classnames'

interface BlogBlok {
  title: any,
  assets: any,
  first_published_at: any,
  authorName: any,
  authorImage: any,
  tags: any,
  content: any,
  related: any,
}

export interface Props extends HasClassName {
  story: any,
  blok: BlogBlok
}

export const BlogArticleSection: FunctionComponent<Props> = ({ className, story, blok }) => {
  const { toDayMonthYear } = useDate()
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    setCurrentUrl(window.location.href)
  }, [])

  return story ? (
    <SpacingWrapper
      size="sm"
      background="LIGHT_IVORY"
      className={className}
    >
      <div className="mb-12 lg:mb-24">
        <Container
          className="mb-8"
          width="md"
        >
          <Heading
            size={{ lg: 'large' }}
            className="mb-6"
            tag="h1"
          >
            { blok.title }
          </Heading>

          <MediaTagline
            date={story.first_published_at}
            name={blok.authorName}
            tags={blok.tags}
          />
        </Container>

        { blok.content.map((container: any) => (
          <Container
            width={container.width}
            className="relative mb-8"
            key={container._uid}
            px={0}
          >
            { container.content.map((section: any) => (
              <StoryblokComponent blok={section} key={section._uid} />
            )) }
          </Container>
        )) }

        <Container
          className="flex mt-12"
          width="md"
        >
          <div className="w-full md:w-1/2">
            <div className="flex items-center">
              <div
                style={{ backgroundImage: `url(${blok.authorImage.filename})` }}
                className="w-12 h-12 mr-3 bg-center bg-no-repeat bg-contain rounded-full"
              />

              <div className="leading-none">
                <div className="mb-1 font-bold">
                  { blok.authorName }
                </div>
                <div className="text-indigo-500 text-opacity-60">
                  { toDayMonthYear(story.first_published_at) }
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-1/2">
            { currentUrl && (
              <div className="flex items-center h-full space-x-4 lg:justify-end">
                <ShareNetwork
                  network="linkedin"
                  url={currentUrl}
                >
                  <LinkedInIcon className="w-6 h-6 opacity-60" />
                </ShareNetwork>

                <ShareNetwork
                  network="facebook"
                  url={currentUrl}
                >
                  <FacebookIcon className="w-6 h-6 opacity-60" />
                </ShareNetwork>

                <ShareNetwork
                  network="twitter"
                  url={currentUrl}
                >
                  <TwitterIcon className="w-6 h-6 opacity-60" />
                </ShareNetwork>
              </div>
            ) }
          </div>
        </Container>

        { blok.related.length > 0 && (
          <div>
            <Container
              className="pt-20 pb-8"
              width="2xl"
            >
              <div className="w-full h-px bg-gray-200" />
            </Container>

            <Container width="md">
              <h6 className="mb-2 font-bold text-indigo-500 uppercase text-opacity-60 md:mb-8">
                { blok.content.relatedTitle }
              </h6>

              <div className="p-6 divide-y divide-gray-200 rounded bg-ivory-500">
                { blok.related.map((related: any, index: number) => (
                  <Anchor
                    className={classNames(
                      "flex flex-col flex-wrap md:flex-row",
                      { 'pt-6 mt-6': index !== 0 }
                    )}
                    key={related._uid}
                    to={related.link}
                  >
                    <div className="md:w-8/12">
                      <Heading
                        size={{ base: 'tiny', md: 'regular' }}
                        className="mb-3 group"
                      >
                        { related.title }
                      </Heading>

                      <MediaTagline
                        tags={related.tags}
                        date={related.date}
                      />
                    </div>

                    <div className="hidden md:block md:w-4/12">
                      <div
                        style={{ backgroundImage: `url(${related.image.filename})` }}
                        className="w-40 h-full ml-auto bg-center bg-no-repeat bg-cover"
                      />
                    </div>
                  </Anchor>
                )) }
              </div>
            </Container>
          </div>
        ) }
      </div>
    </SpacingWrapper>
  ) : null
}
