import { SeoMetaTagsPluginField } from '@storyblok/react'

const seoFields = [
  'description',
  'og_description',
  'og_image',
  'og_title',
  'title',
  'twitter_description',
  'twitter_image',
  'twitter_title'
]

export function SeoMetaTags(meta: SeoMetaTagsPluginField) {
  const fields = Object.entries(meta).filter(([key, value]) => seoFields.includes(key) && value)

  return fields.map(([unformatedKey, value]) => {
    const key = unformatedKey.replace(/^og_/, 'og:').replace(/^twitter_/, 'twitter:')
    return <meta key={key} name={key} content={value} />
  })
}
