import { MessagesWomanBubble } from 'components/Scl/Icon/MessagesWomanBubble'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export const SupportTrigger: FunctionComponent<HasClassName> = ({ className }) => {
  function openSupportWindow() {
    (window as any).$kundo_chat.start_chat()
  }

  return (
    <div
      className={classNames('flex items-center justify-center w-16 h-16 text-white bg-indigo-500 rounded-full shadow cursor-pointer', className)}
      onClick={openSupportWindow}
    >
      <MessagesWomanBubble className="w-6 h-6" />
    </div>
  )
}
