import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const ChevronRightIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      onClick={onClick}
      fill="none"
    >
      <path
      d="M8.71 17.3003C9.1 17.6903 9.73 17.6903 10.12 17.3003L14.71 12.7103C15.1 12.3203 15.1 11.6903 14.71 11.3003L10.12 6.71027C9.73 6.32027 9.1 6.32027 8.71 6.71027C8.32 7.10027 8.32 7.73027 8.71 8.12027L12.59 12.0103L8.71 15.8903C8.32 16.2803 8.33 16.9203 8.71 17.3003Z"
      fill="currentColor"
    />
    </svg>
  )
}
