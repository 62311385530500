import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { CollapseProvider } from 'components/Scl/Tools/Collapse/CollapseProvider'
import { FadeTransition } from 'components/Scl/Tools/Transitions/FadeTransition'
import { StructuredData } from '~/components/StructuredData/StructuredData'
import { FunctionComponent, useRef, useState } from 'react'
import { PlusIcon } from 'components/Scl/Icon/PlusIcon'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import classNames from 'classnames'

interface Item {
  categories: string
  title: string
  text: string
  open: boolean
}

export interface Props extends Blok {
  items: Item[]
}

export const CollapseList: FunctionComponent<BlokProps<Props> & HasClassName> = ({ blok, className }) => {
  const stringToArray = (categories: string) => {
    return categories.split(',').map(c => c.trim())
  }

  const categories = blok.items
    .flatMap(item => stringToArray(item.categories))
    .filter((value, index, self) => {
      return self.indexOf(value) === index
    })
    .filter(value => {
      return value !== ''
    })

  const [activeCategory, setActiveCategory] = useState<string>(categories[0])

  const onCategoryClicked = (category: string) => {
    setActiveCategory(category)
  }

  const shouldBeHidden = (categories: string) => {
    if (!categories) {
      return false
    }

    return !stringToArray(categories)
      .includes(activeCategory)
  }

  const renderer = {
    paragraph: (text: string) => `<p class="mt-4">${text}</p>`,
  }
  return (
    <div className={className}>
      <div className="grid grid-cols-4">
        {categories &&
          <div
            className="hidden pr-8 border-r border-gray-200 lg:block"
          >
            {categories.map((category, index) => (
              <div
                key={index}
                onClick={() => onCategoryClicked(category)}
                className={
                  classNames('px-8 py-4 font-bold rounded cursor-pointer',
                    { 'bg-blue-100 text-blue-700': activeCategory === category })
                }
              >
                {category}
              </div>
            ))}

          </div>
        }

        <div
          className={classNames('col-span-4 lg:pl-8', { 'lg:col-span-3': categories.length > 0 })}
        >
          {blok.items.map((item, index) => (
            <div
              className={classNames('border-b border-gray-200', { 'lg:hidden': shouldBeHidden(item.categories) })}
              key={index}
            >
              <CollapseProvider
                initial-state={item.open}
              >
                {({ open, toggle }) => (
                  <>
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={toggle}
                    >
                      <div className="py-5 text-lg font-bold leading-none">
                        {item.title}
                      </div>

                      <div>
                        <PlusIcon
                          className={classNames('w-5 h-5 transition-transform duration-150', { 'transform rotate-45': open })}
                        />
                      </div>
                    </div>

                    <FadeTransition>
                      {open && (
                        <MarkdownRenderer
                          markdown={item.text}
                          renderer={renderer}
                          className="pb-10 -mt-4"
                        />
                      )}
                    </FadeTransition>
                  </>
                )}
              </CollapseProvider>
            </div>
          ))}
        </div>

        <StructuredData
          questions={blok.items.map(item => ({
            markdown: item.text,
            title: item.title,
          }))}
        />
      </div>
    </div>
  )
}
