import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

export interface Props extends HasClassName {
  onClick?: () => void
}

export const PlusIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      onClick={onClick}
    >
      <path
        fill="currentColor"
        d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
      />
    </svg>
  )
}
