import { CompactMediaTeaserList } from 'components/Storyblok/Partials/Teaser/CompactMediaTeaserList'
import { RegularMediaTeaserList } from 'components/Storyblok/Partials/Teaser/RegularMediaTeaserList'
import { LargeMediaTeaser } from 'components/Storyblok/Partials/Teaser/LargeMediaTeaser'
import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Blok, BlokProps } from '@storyblok/react'
import { Anchor } from '~/components/Anchor/Anchor'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import { first } from 'lodash-es'

interface Props extends Blok {
  background: Background
  heading: any
  items: any[]
  title: any
  text: any
}

export const PressReleaseSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const featuredTeaser = first(blok.items)
  const regularTeasers = blok.items.slice(1, 4)
  const compactTeasers = blok.items.slice(4)


  return (
    <SpacingWrapper
      background={blok.background}
      size="md"
    >
      <Container width="md">
        <div className="max-w-md mx-auto mb-16 text-center">
          <HeadingStoryblok
            blok={blok.heading[0]}
            className="mb-5"
          />

          <MarkdownRenderer
            markdown={blok.text}
            className="text-lg"
          />
        </div>

        <Anchor
          to={featuredTeaser.link}
          className="block"
        >
          <LargeMediaTeaser
            image={featuredTeaser.asset.filename}
            preamble={featuredTeaser.preamble}
            title={featuredTeaser.title}
            date={featuredTeaser.date}
            tags={featuredTeaser.tags}
          />
        </Anchor>

        <div className="lg:mr-16">
          <RegularMediaTeaserList
            teasers={regularTeasers}
            title={blok.title}
          />

          <CompactMediaTeaserList
            teasers={compactTeasers}
          />
        </div>
      </Container>
  </SpacingWrapper>
  )
}
