import { AmountRangeSlider } from 'components/Slider/AmountRangeSlider'
import { FlexPeriodSlider } from 'components/Slider/FlexPeriodSlider'
import { BaseCalculator } from 'components/Storyblok/Partials/Hero/BaseCalculator'
import { FormLabel } from 'components/Scl/Typography/FormLabel'
import { Heading } from 'components/Scl/Typography/Heading'
import { FunctionComponent, useState } from 'react'
import { Blok, BlokProps } from '@storyblok/react'
import { formatMoney } from '@capcito/ui-money'
import { Flex } from '@capcito/ui-core-logic'
import { useRouter } from 'next/router'

export interface Props extends Blok {
  text: string
  disclaimer: any
  interest: any
  amountLabel: any
  title: any
  periodLabel: any
  periodUnit: string
  totalLabel: any
  cta: any
}

export const InvoiceDiscountingCalculator: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const { locale } = useRouter()
  const [amount, setAmount] = useState(100000)
  const [period, setPeriod] = useState(15)

  const culture = locale ?? 'sv'

  const formattedAmount = formatMoney(amount, 'SEK', culture)

  const interest = parseFloat(`${blok.interest / 100}`)

  const disclaimer = blok.disclaimer.replace('{interest}', blok.interest)

  const cost = Flex.cost(amount, interest, period)

  const result = {
    total: formatMoney(cost, 'SEK', culture)
  }

  return (
    <BaseCalculator
      title={blok.title}
      btn={blok.cta[0]}
      leftCol={<>
        <div className="flex mb-2 xl:mb-4">
          <div className="w-1/3">
            <FormLabel>
              {blok.amountLabel}
            </FormLabel>
          </div>

          <div className="w-2/3 text-right">
            <Heading
              className="text-blue-500"
              size="tiny"
            >
              {formattedAmount}
            </Heading>
          </div>
        </div>

        <AmountRangeSlider value={amount} onInput={setAmount} />
      </>}
      rightCol={<>
        <div className="flex mb-2 xl:mb-4">
          <div className="w-1/2">
            <FormLabel>
              {blok.periodLabel}
            </FormLabel>
          </div>

          <div className="w-1/2 text-right">
            <Heading
              className="text-blue-500"
              size="tiny"
            >
              {`${period} ${blok.periodUnit}`}
            </Heading>
          </div>
        </div>

        <FlexPeriodSlider value={period} input={setPeriod} />
      </>}
      result={<>
        <Heading
          size={{ base: 'small', lg: 'regular' }}
          className="text-blue-500"
        >
          {result.total}
        </Heading>
        <div className="text-sm font-semibold text-indigo-500 text-opacity-50">
          {blok.totalLabel}
        </div>
      </>}
      footer={
        <p className="text-sm font-semibold text-indigo-500 text-opacity-50">
          {disclaimer}
        </p>
      }
    />
  )
}

