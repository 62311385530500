import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Anchor } from '~/components/Anchor/Anchor'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  columns: any[],
  links: any[],
}

export const LinkSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      size="md"
      background={blok.background}
    >
      <Container
        width="xl"
        className="flex flex-col md:items-center"
      >
        <HeadingStoryblok
          blok={blok.heading[0]}
          className="mb-12"
        />

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4">
          { blok.columns.map((column: any) => (
            <div
              key={column._uid}
              className="space-y-3"
            >
              { column.links.map((link: any) => (
                <Anchor
                  className="block font-semibold text-blue-500 cursor-pointer hover:underline"
                  key={link._uid}
                  to={link.link}
                >
                  { link.text }
                </Anchor>
              )) }
            </div>
          )) }
        </div>
      </Container>
    </SpacingWrapper>
  )
}
