import { FunctionComponent } from 'react'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { Container } from 'components/Scl/Layout/Container/Container'

export interface Props extends Blok {
  title: any,
  assets: any,
}

export const HeroPartnerFooter: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <Container
      className="mt-6"
      width="2xl"
    >
      <div className="hidden lg:block">
        <div className="text-sm text-center">
          { blok.title }
        </div>

        <div className="flex items-center justify-center mt-3 mb-6 space-x-6">
          { blok.assets.map((asset: any, index: number) => (
            <img
              src={asset.filename}
              alt={asset.alt}
              key={index}
              className="h-6"
            />
          ))}
        </div>
      </div>

      <div className="h-px bg-black opacity-10" />
    </Container>
  )
}
