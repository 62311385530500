import { FunctionComponent, ReactNode } from 'react'
import { Transition } from '@headlessui/react'

export interface Props {
  appear?: boolean,
  children: ReactNode,
}

export const FadeTransition: FunctionComponent<Props> = ({ children, appear = true }) => {
  return (
    <Transition
      enterTo="opacity-100"
      leaveTo="opacity-0"
      leaveFrom="opacity-100"
      enter="transition-opacity duration-300 ease-out"
      leave="transition-opacity duration-200 ease-in"
      enterFrom="opacity-0"
      show={appear}
    >
      { children }
    </Transition>
  )
}
