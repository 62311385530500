import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const RocketIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <path
      d="M14.5135 13.1566C13.8544 13.3766 13.1321 13.1953 12.6427 12.687C12.1532 12.1786 11.9789 11.4287 12.1911 10.7445C12.9462 8.30456 13.4215 7.81293 13.6237 7.60306L13.6486 7.57718C13.704 7.52209 13.7289 7.44178 13.7151 7.36348C13.7014 7.28507 13.6513 7.21874 13.5812 7.18618C11.1698 6.08816 8.37763 6.40629 6.25297 8.02114C4.12831 9.636 2.99264 12.3032 3.2723 15.0215C3.27957 15.0921 3.25623 15.1623 3.20861 15.2132C2.36117 16.3359 1.67696 17.5815 1.17784 18.9104C0.749589 19.7924 0.842345 20.8506 1.41692 21.6378C1.7629 21.9829 2.22993 22.1658 2.70923 22.1439C4.03732 21.9844 5.30382 21.4739 6.38769 20.6613C6.46332 20.612 6.55945 20.612 6.63507 20.6613C9.23966 22.3883 12.6046 22.2489 15.0683 20.3118C17.532 18.3747 18.5732 15.0497 17.6778 11.9788C17.6562 11.9037 17.6002 11.8446 17.5283 11.8207C17.4563 11.7962 17.3774 11.8098 17.3169 11.8571C16.447 12.4271 15.503 12.8647 14.5135 13.1566ZM9.73938 13.6808C9.99113 13.4375 10.3767 13.423 10.6448 13.6467C10.9129 13.8704 10.9821 14.2645 10.8074 14.5721C7.188 18.1246 4.26923 19.9157 3.09046 20.2051C3.01287 20.2238 2.93148 20.1996 2.87516 20.1412C2.81884 20.0827 2.79555 19.9982 2.81354 19.9176C2.92822 19.4776 3.09016 19.0523 3.2963 18.6498C3.41261 18.4092 3.55292 18.1428 3.72092 17.8467C3.76358 17.7729 3.84095 17.7284 3.92382 17.73C4.00669 17.7316 4.08244 17.779 4.12246 17.8543L4.14184 17.8908C4.17211 17.9482 4.22387 17.9902 4.28492 18.0067C4.34561 18.024 4.4105 18.0143 4.464 17.9799C6.35545 16.7321 8.12261 15.2919 9.73938 13.6808Z"
      fill="currentColor"
    />
    <path
      d="M16.9643 9.63675C16.9654 9.25581 16.8195 8.89028 16.5591 8.62188C16.0114 8.07851 15.1489 8.07851 14.6012 8.62188C14.1198 9.41676 13.752 10.2799 13.5092 11.1845C13.4563 11.3555 13.4999 11.5429 13.6223 11.6699C13.7446 11.7969 13.9251 11.8422 14.0898 11.7873C14.9611 11.5368 15.7929 11.1565 16.5591 10.6583C16.8205 10.3879 16.9665 10.0199 16.9643 9.63675Z"
      fill="currentColor"
    />
    <path
      d="M22.1538 1.93181L20.7175 2.57198C20.3879 2.72855 20.0856 2.94101 19.8231 3.20064L18.7154 4.35064C17.4901 4.01711 16.1855 4.3504 15.2492 5.23614L14.9234 5.57635C14.7432 5.76347 14.7432 6.06678 14.9234 6.25389L18.84 10.3191C19.0202 10.5062 19.3124 10.5062 19.4926 10.3191L19.8194 9.98085C20.6757 9.00983 20.9987 7.65386 20.6769 6.38039L21.7846 5.23039C22.034 4.95783 22.2383 4.64439 22.3892 4.30273L23.0049 2.81156C23.1408 2.55738 23.1035 2.24125 22.9126 2.0286C22.7072 1.8278 22.4 1.78862 22.1538 1.93181Z"
      fill="currentColor"
    />
    </svg>
  )
}
