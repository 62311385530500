interface StepValue {
  [key: string]: number
}

export const useRange = () => {
  const asStepValues = (values: number[]): StepValue => {
    const result: StepValue = {}

    values.forEach((value, iteration) => {
      if (iteration === 0) {
        result['min'] = value
      } else if (iteration === values.length - 1) {
        result['max'] = value
      } else {
        const percentage = (100 / values.length) * iteration
        result[`${percentage}%`] = value
      }
    })

    return result
  }

  return {
    asStepValues
  }
}
