import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeroUspFooter } from 'components/Storyblok/Partials/Hero/HeroUspFooter'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { CapcitoLogo } from 'components/Scl/Logo/CapcitoLogo'
import { CrossIcon } from 'components/Scl/Icon/CrossIcon'
import { buildQueryParams } from 'helpers/buildQuery'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { Blok, BlokProps } from '@storyblok/react'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import { useRouter } from 'next/router'
import { AppConfig } from 'config/app'

interface Props extends Blok {
  background: Background
  partner: string
  heading: any
  disclaimer: any,
  cta: any
  footer: any,
  logo: any
}

export const OAuthHeroSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const { query } = useRouter()

  const partner = JSON.parse(blok.partner)

  function getAuthUrl() {
    if (!partner.meta?.oauth_auth_path) {
      throw new Error('The key [partner.meta.oauth_auth_path] must be set.')
    }

    const baseUrl = `${AppConfig.baseUrl}/${partner.meta?.oauth_auth_path}`
    const asArray = Object.entries(query);

    const q = Object.fromEntries(asArray.filter(([key]) => key !== 'slug'))

    return q
      ? `${baseUrl}?${buildQueryParams(q as any)}`
      : baseUrl
  }

  const renderer = {
    link: (href: string, title: string, text: string) => `<a class="text-blue-500 hover:underline" target="_blank" href="${href}">${text}</a>`,
  }

  return (
    <SpacingWrapper
      background="INDIGO"
      className="relative"
      size="sm"
    >
      <div className="relative z-10 mt-16 lg:mt-24">
        <Container>
          <div className="flex items-center justify-center mb-4 space-x-3 text-indigo-100">
            <CapcitoLogo
              showEnv={false}
              svgClass="w-22"
            />

            <CrossIcon className="w-5 h-5" />

            <img
              src={blok.logo.filename}
              alt={blok.logo.alt}
              className="w-22"
            />
          </div>

          <HeadingStoryblok
            className="max-w-xs px-3 mx-auto mb-20 text-center lg:max-w-2xl lg:px-8 text-ivory-500 lg:mb-16"
            blok={blok.heading[0]}
          />

          <div className="text-center mb-18 lg:mb-24">
            <a
              data-track="blok.cta[0].trackingId"
              href={getAuthUrl()}
            >
              <Btn
                theme="primary"
                type="button"
              >
                {blok.cta[0].text}
              </Btn>
            </a>

            <MarkdownRenderer
              className="max-w-xs px-12 mx-auto mt-4 text-sm text-white"
              markdown={blok.disclaimer}
              renderer={renderer}
            />
          </div>

          <div
            data-businessunit-id="5bf3d7c2608c550001c8469e"
            data-template-id="5419b6ffb0d04a076446a9af"
            data-scroll-to-list="true"
            className="trustpilot-widget"
            data-style-height="20px"
            data-allow-robots="true"
            data-style-width="100%"
            data-stars="1,2,3,4,5"
            data-no-reviews="hide"
            data-locale="sv-SE"
            data-theme="dark"
          >
            <a
              href="https://se.trustpilot.com/review/www.capcito.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot
            </a>
          </div>
        </Container>

        <HeroUspFooter
          blok={blok.footer[0]}
        />
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="absolute top-0 left-0 z-0 bg-circle-top-left"
        viewBox="0 0 338 461"
        fill="none"
      >
        <g clipPath="url(#clip0_33_34)">
          <circle
            fill="#082845"
            cy="223"
            cx="60"
            r="238"
          />
          <circle
            fill="#082845"
            cy="421"
            cx="298"
            r="40"
          />
        </g>
        <defs>
          <clipPath id="clip0_33_34">
            <rect
              fill="white"
              height="461"
              width="338"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        className="absolute top-0 right-0 z-0 bg-circle-right"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 208 361"
        fill="none"
      >
        <g clipPath="url(#clip0_33_38)">
          <circle
            fill="#082845"
            cx="180.5"
            cy="180.5"
            r="180.5"
          />
        </g>
        <defs>
          <clipPath id="clip0_33_38">
            <rect
              fill="white"
              height="361"
              width="208"
            />
          </clipPath>
        </defs>
      </svg>
    </SpacingWrapper>
  )
}
