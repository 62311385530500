import { Container } from '../Scl/Layout/Container/Container'
import { HeadingStoryblok } from './HeadingStoryblok'
import { Blok, BlokProps } from '@storyblok/react'
import { SpacingWrapper } from './SpacingWrapper'
import { Btn } from '../Scl/Form/Button/Btn'
import { Anchor } from '../Anchor/Anchor'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  text: string,
  heading: any,
  cta: any
  assets: any[]
}

export const PartnerSection: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <SpacingWrapper
      size="sm"
      background="INDIGO"
    >
      <Container>
        <div className="flex flex-wrap items-center justify-between lg:flex-no-wrap">
          <div className="w-full mb-16 lg:w-5/12 lg:max-w-md text-ivory-500 lg:mb-0">
            <HeadingStoryblok
              className="mb-6"
              blok={blok.heading[0]}
            />

            <div>
              {blok.text}
            </div>
            {blok.cta.length > 0 && (
              <div className="mt-8">
                <Anchor
                  dataTrack={blok.cta[0].trackingId}
                  to={blok.cta[0].link}
                >
                  <Btn theme={blok.cta[0].theme}>
                    {blok.cta[0].text}
                  </Btn>
                </Anchor>
              </div>
            )}
          </div>


          <div className="w-full lg:w-6/12">
            <div className="grid items-center justify-center grid-cols-2 gap-16 lg:grid-cols-3">
              {
                blok.assets.map((image, index) => (
                  <img
                    src={image.filename}
                    alt={image.alt}
                    className="mx-auto"
                    key={index}
                  />
                ))
              }
            </div>
          </div>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
