import { HasClassName } from 'models/HasClassName'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

interface Props extends HasClassName {
  theme: Background
}

export const GlobalOffset: FunctionComponent<Props> = ({ theme, className }) => {
  return (
    <div
      className={classNames({
        'bg-ivory-300': theme === 'LIGHT_IVORY',
        'bg-ivory-500': theme === 'IVORY',
        'bg-gray-100':  theme === 'GRAY',
        'bg-white':     theme === 'WHITE',
      }, className)}
    />
  )
}
