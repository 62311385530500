import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  text: string
}

export const BlogPreamble: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <div className="max-w-screen-md px-6 mx-auto mt-8 text-xl font-bold leading-tight text-indigo-500">
      {blok.text}
    </div>
  )
}
