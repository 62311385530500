import { CollapseList } from 'components/Storyblok/Partials/CollapseList'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { Btn } from 'components/Scl/Form/Button/Btn'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  collapse: any[],
  intro: any,
  cta: any,
}

export const JobSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      background={blok.background}
      size="md"
    >
      <Container width="md">
        <div className="mb-12 text-center lg:mb-16">
          <HeadingStoryblok
            className="mb-6"
            blok={blok.heading[0]}
          />

          <div className="text-xl leading-normal">
            { blok.intro }
          </div>
        </div>

        <CollapseList
          blok={blok.collapse[0]}
        />

        <div className="mt-12 text-center">
          <Anchor
            className="block text-center"
            to={blok.cta[0].link}
            data-track={blok.cta[0].trackingId}
          >
            <Btn theme={blok.cta[0].theme}>
              { blok.cta[0].text }
            </Btn>
          </Anchor>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
