import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { BlokProps, Blok, LinkField } from '@storyblok/react'
import { Heading } from 'components/Scl/Typography/Heading'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'
import { useDate } from 'hooks/date'

export interface Props extends Blok {
  heading: any[],
  releases: any[],
}

export const ChangelogSection: FunctionComponent<BlokProps<Props> & HasClassName> = (props) => {
  const { className, blok } = props
  const { toDayMonthYear } = useDate()

    function toReadableType(type: string) {
      switch (type) {
        case 'FEAT':
          return 'Feature'
        case 'FIX':
          return 'Fix'
        case 'CHORE':
          return 'Chore'
      }
    }

    function getTicketDesc(link: LinkField) {
      const matches = link.cached_url
        .match(/TGA(D?)-\d+/g)

      return matches
        ? matches[0]
        : null
    }
  return (
    <SpacingWrapper
      background="GRAY"
      size="lg"
    >
      <div className="max-w-5xl mx-auto md:w-10/12">
        <HeadingStoryblok
          blok={blok.heading[0]}
          className="text-center"
        />

        <Container
          className="py-16"
          width="md"
        >
          <div className="space-y-8">
            { blok.releases.map((release) => (
              <div key={release._uid}>
                <div className="mb-4 text-sm font-semibold">
                  <Heading size="tiny">
                    { toDayMonthYear(release.date) }
                  </Heading>
                </div>

                <div className="w-full p-8 bg-white rounded shadow">
                  <ul className="ml-4 space-y-1 text-indigo-500 text-opacity-75 list-disc">
                    { release.items.map((item: any, index: number) => (
                      <li key={index}>
                        <div className="flex justify-between">
                          <p className="pr-6">
                            <span className="font-bold">{ toReadableType(item.type) }:</span>
                            <span>{ item.text }</span>
                          </p>

                          { item.link && (
                            <Anchor
                              className="flex-none text-sm font-bold text-blue-500 hover:underline"
                              to={item.link}
                              target="_blank"
                            >
                              { getTicketDesc(item.link) }
                            </Anchor>
                          )}
                        </div>
                      </li>
                    )) }
                  </ul>
                </div>
              </div>
            )) }
          </div>
        </Container>
      </div>
    </SpacingWrapper>
  )
}
