import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  onClick?: () => void
}

export const TelescopeIcon: FunctionComponent<Props> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
    <g>
      <path
        d="M-579,409.8c0.2,0.1,0.4,0.2,0.5,0.5c0.2,0.5,0.3,0.9,0.5,1.4c0,0.1,0.2,0.2,0.3,0.3c0.5,0.2,1,0.4,1.5,0.6
		c0.2,0.1,0.4,0.2,0.4,0.5c0,0.2-0.1,0.4-0.4,0.5c-0.5,0.2-1,0.4-1.5,0.5c-0.2,0.1-0.3,0.1-0.3,0.3c-0.2,0.5-0.4,1-0.6,1.5
		c-0.1,0.2-0.2,0.4-0.5,0.4c-0.3,0-0.4-0.2-0.5-0.4c-0.2-0.5-0.4-1-0.6-1.5c-0.1-0.1-0.2-0.3-0.3-0.3c-0.5-0.2-1-0.4-1.5-0.6
		c-0.3-0.1-0.4-0.3-0.4-0.5c0-0.3,0.2-0.4,0.4-0.5c0.5-0.2,1-0.4,1.5-0.6c0.1-0.1,0.3-0.2,0.3-0.3c0.2-0.4,0.4-0.9,0.5-1.3
		c0.1-0.2,0.2-0.4,0.5-0.5C-579.1,409.8-579,409.8-579,409.8z"
      />
      <path
        d="M-567.5,419.9c-0.6,0-1.1-0.4-1.4-1c-0.5-1.4-1-2.7-1.5-4.1c-0.4-0.9,0-1.6,0.9-2c1.2-0.5,2.4-1,3.6-1.6
		c0.9-0.4,1.7-0.1,2.1,0.9c0.6,1.6,1.2,3.1,1.8,4.7c0.3,0.9,0,1.7-1,1.9c-1.3,0.4-2.6,0.7-3.9,1
		C-567.2,419.9-567.4,419.9-567.5,419.9z"
      />
      <path
        d="M-571.9,429.3c0,0.6,0,1.1,0,1.7c0,0.6-0.4,1-0.9,1c-0.6,0-1-0.4-1-1c0-1.1,0-2.3,0-3.4c0-0.2,0.1-0.4-0.2-0.4
		c-0.2-0.1-0.3,0.1-0.4,0.3c-0.8,1.3-1.6,2.7-2.3,4c-0.3,0.5-0.8,0.6-1.2,0.5c-0.6-0.2-0.8-0.8-0.5-1.4c0.6-1.1,1.3-2.2,1.9-3.3
		c0.9-1.6,1.8-3.2,2.8-4.8c0.3-0.6,1-0.7,1.5-0.4c0.1,0.1,0.2,0.2,0.3,0.4c1.6,2.7,3.1,5.3,4.6,8c0.3,0.5,0.2,1-0.3,1.3
		c-0.5,0.3-1.1,0.1-1.4-0.4c-0.8-1.4-1.6-2.7-2.4-4.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.1c-0.1,0-0.2,0.2-0.2,0.3
		C-571.9,428-571.9,428.7-571.9,429.3z"
      />
      <path
        d="M-563.7,429.7c-0.3,0-0.5-0.2-0.6-0.4c-0.2-0.6-0.5-1.2-0.7-1.8c-0.1-0.2-0.2-0.3-0.4-0.4c-0.6-0.2-1.3-0.5-1.9-0.7
		c-0.3-0.1-0.5-0.3-0.5-0.6c0-0.3,0.2-0.4,0.5-0.6c0.6-0.2,1.3-0.5,1.9-0.7c0.2-0.1,0.3-0.2,0.3-0.3c0.2-0.6,0.5-1.2,0.7-1.8
		c0.1-0.3,0.3-0.5,0.6-0.5c0.3,0,0.5,0.2,0.6,0.5c0.2,0.6,0.5,1.2,0.7,1.8c0.1,0.1,0.2,0.3,0.3,0.3c0.6,0.3,1.3,0.5,1.9,0.7
		c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.3,0,0.6-0.4,0.8c-0.6,0.2-1.3,0.5-1.9,0.7c-0.2,0.1-0.3,0.2-0.4,0.4c-0.2,0.6-0.5,1.2-0.7,1.8
		C-563.2,429.6-563.4,429.7-563.7,429.7z"
      />
      <path
        d="M-576,417.2c0-0.6,0.3-1.1,0.8-1.3c0.9-0.4,1.8-0.7,2.8-1c0.3-0.1,0.5,0,0.6,0.3c0.5,1.4,1.1,2.8,1.6,4.2
		c0.1,0.3,0,0.5-0.3,0.6c-0.9,0.3-1.8,0.6-2.7,0.9c-0.7,0.2-1.5-0.1-1.7-0.9c-0.3-0.8-0.6-1.7-1-2.5C-576,417.4-576,417.2-576,417.2
		z"
      />
      <path
        d="M-581,422.2c-0.4,0-0.7-0.2-0.9-0.6c-0.1-0.3-0.3-0.6-0.4-0.9c-0.2-0.5,0.1-0.9,0.6-1.1c0.8-0.3,1.6-0.6,2.5-0.9
		c0.5-0.2,0.9-0.3,1.4-0.5c0.4-0.1,0.6,0,0.7,0.3c0.2,0.5,0.4,1,0.6,1.5c0.1,0.3,0,0.6-0.3,0.7c-1.3,0.5-2.6,0.9-3.9,1.4
		C-580.8,422.1-580.9,422.2-581,422.2z"
      />
    </g>
    </svg>
  )
}
