import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { Anchor } from '~/components/Anchor/Anchor'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  usps: any[],
}

export const LinkUspSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      size="lg"
      background={blok.background}
    >
      <Container width="2xl">
        <HeadingStoryblok
          className="mb-12 text-center lg:mb-20"
          blok={blok.heading[0]}
          v-if="blok.heading.length"
        />

        <div className="justify-center space-y-12 lg:flex lg:space-y-0 lg:space-x-6">
          { blok.usps.map((usp: any, index: number) => (
            <div
              className="flex flex-col justify-between lg:w-1/3"
              key={index}
            >
              <div>
                <HeadingStoryblok
                  className="mb-4"
                  blok={usp.heading[0]}
                />

                <div className="mb-4">
                  { usp.text }
                </div>
              </div>

              <Anchor
                className="font-bold text-blue-500 hover:underline"
                to={usp.link[0].link}
              >
                { usp.link[0].text }
              </Anchor>
            </div>
          )) }
        </div>
      </Container>
    </SpacingWrapper>
  )
}
