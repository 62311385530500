type Dictionary<T> = { [key: string]: T }

export function buildQueryParams(params: Dictionary<string | (string | null)[]>): string|null {
  if (!params) {
    return null
  }

  return Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')
}
