import marked, { MarkedOptions } from 'marked'

export const useMarkdown = () => {
  const notImplementedError = (method: string): never => {
    throw new Error(`Method [marked.${method}] is not yet implemented`)
  }

  const getHeadingClassList = (level: number): string => {
    const classList: string[] = []

    if (level === 5) {
      classList.push('font-bold', 'text-xl', 'mb-4', 'leading-none')
    }

    if (level === 4) {
      classList.push('font-semibold', 'font-serif', 'text-4xl', 'my-8', 'leading-none')
    }

    if (level === 3) {
      classList.push('font-semibold', 'font-serif', 'text-3xl', 'my-12', 'leading-none')
    }

    return classList.join(' ')
  }

  const getListType = (ordered: boolean): string => {
    return (ordered) ? 'ol' : 'ul'
  }

  const getListClassList = (ordered: boolean): string => {
    return (ordered) ? 'list-decimal' : 'list-disc'
  }

  const defaults: MarkedOptions = {
    renderer: {
      options: {},
      link: (href, title, text) => `<a class="underline hover:no-underline hover:text-blue-500" href="${href}" ${title ? `title="${title}"` : ''}>${text}</a>`,
      heading: (text, level) => `<h${level} class="${getHeadingClassList(level)}">${text}</h${level}>`,
      list: (body, ordered) => `<${getListType(ordered)} class="${getListClassList(ordered)} ml-8 mt-4">${body}</${getListType(ordered)}>`,
      strong: (text) => `<span class="font-bold">${text}</span>`,
      em: (text) => `<span class="italic">${text}</span>`,
      listitem: (text) => `<li>${text}</li>`,
      paragraph: (text) => `<p>${text}</p>`,
      text: (text) => text,
      blockquote: () => notImplementedError('blockquote'),
      tablecell: () => notImplementedError('tablecell'),
      checkbox: () => notImplementedError('checkbox'),
      tablerow: () => notImplementedError('tablerow'),
      codespan: () => notImplementedError('codespan'),
      table: () => notImplementedError('table'),
      image: () => notImplementedError('image'),
      code: () => notImplementedError('code'),
      html: () => notImplementedError('html'),
      del: () => notImplementedError('del'),
      hr: () => notImplementedError('hr'),
      br: () => '<br />',
    }
  }

  function mergeRenderOptions(defaults: MarkedOptions, options: {}): object {
    const merged = Object.assign(defaults)
    merged.renderer = Object.assign(defaults.renderer, options)

    return (merged)
  }

  const parse = (markdown: string, options: object = {}) =>

    marked(markdown, mergeRenderOptions(defaults, options))

  return {
    parse
  }
}
