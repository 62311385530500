import { Blok, BlokProps, SeoMetaTagsPluginField, StoryblokComponent } from '@storyblok/react'
import { GlobalOffset } from 'components/Storyblok/Partials/Global/GlobalOffset'
import { GlobalNavigation } from 'components/Storyblok/Partials/Global/GlobalNavigation'
import { GlobalFooter } from 'components/Storyblok/Partials/Global/GlobalFooter'
import { SupportTrigger } from '~/components/SupportTrigger/SupportTrigger'
import { BaseLayout } from 'components/BaseLayout/BaseLayout'
import { Background } from 'models/Background'
import { FunctionComponent } from 'react'
import Head from 'next/head'
import { SeoMetaTags } from '~/components/SeoMetaTags/SeoMetaTags'

interface PageBlok extends Blok {
  meta: SeoMetaTagsPluginField
  offset: Background
  sections: Blok[]
}
interface Props {
  blok: PageBlok,
  story: any,
}

export const Page: FunctionComponent<Props> = ({ story, blok }) => {
  return (
    <BaseLayout>
      <Head>
        <title>{blok.meta.title}</title>
        {SeoMetaTags(blok.meta)}
      </Head>

      <GlobalNavigation />

      <main className="min-h-screen">
        <GlobalOffset
          className="pt-26 lg:pt-32 xl:pt-22"
          theme={blok.offset}
        />

        {blok.sections.map(b => <StoryblokComponent blok={b} key={b._uid} story={story}/>)}

        <div className="sticky bottom-0 z-20 flex justify-end">
          <SupportTrigger className="mb-3 mr-3 -mt-16 md:mr-4 md:mb-4 md:-mt-20" />
        </div>
      </main>

      <GlobalFooter />
    </BaseLayout>
  )
}
