import { FunctionComponent, useState } from 'react'
import { Env } from 'config/env'

interface Props {
  onClick?: () => void
  svgClass?: string
  showEnv?: boolean
}

export const CapcitoLogo: FunctionComponent<Props> = ({ svgClass, onClick, showEnv = true }) => {
  const [envVisible, setEnvVisible] = useState<boolean>(showEnv)

  function getShortName(): string|null {
    switch (Env.name) {
      case 'development':
        return 'LO'
      case 'preview':
        return 'PR'
      default:
        return null
    }
  }

  function getFullName(): string|null {
    switch (Env.name) {
      case 'development':
        return 'Development'
      case 'preview':
        return 'Preview'
      default:
        return null
    }
  }

  function onEnvClicked() {
    setEnvVisible(false)
  }

  const shortName = getShortName()
  const fullName = getFullName()

  return (
    <div className="flex">
      <div className="relative inline-block">
        <svg
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
          className={svgClass}
          xmlSpace="preserve"
          viewBox="0 0 81 21"
          onClick={onClick}
        >
          <g>
            <path
              d="M63.42,15.72c0.28,0.28,0.62,0.5,0.99,0.65c0.43,0.16,0.89,0.24,1.36,0.24c0.41,0,0.83-0.04,1.23-0.11c0.26-0.05,0.52-0.13,0.77-0.23l-0.62-1.91c-0.04,0.02-0.09,0.03-0.13,0.05c-0.23,0.07-0.47,0.1-0.71,0.1c-0.43,0.02-0.84-0.15-1.13-0.47c-0.19-0.2-0.32-0.44-0.37-0.7c-0.06-0.26-0.09-0.53-0.09-0.79V6.85h2.67V5h-2.67V1.5h-2.27V5h-1.19v1.85h1.19v6.2C62.47,14.21,62.79,15.1,63.42,15.72z"
              fill="currentColor"
            />
            <path
              d="M12.54,11.67c-1.46,2.51-4.68,3.35-7.18,1.89C2.85,12.1,2,8.88,3.47,6.37s4.68-3.35,7.18-1.89c0.78,0.46,1.43,1.11,1.89,1.89l2.33-0.98c-2-3.79-6.7-5.24-10.49-3.24s-5.24,6.7-3.24,10.49s6.7,5.24,10.49,3.24c1.38-0.73,2.51-1.86,3.24-3.24L12.54,11.67z"
              fill="currentColor"
            />
            <path
              d="M57.11,16.32V5.01h2.36v11.31H57.11z"
              fill="currentColor"
            />
            <path
              d="M36.63,4.43c-0.07,0-0.13,0-0.2,0l0,0c-1.46,0-2.88,0.52-3.99,1.47V5.06h-2.18v15.93h2.47v-5.75c0.85,0.91,2.04,1.54,3.7,1.54c3.41,0.06,6.22-2.67,6.28-6.08C42.76,7.3,40.04,4.49,36.63,4.43z M36.23,14.56c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96c2.19,0,3.96,1.77,3.96,3.96c0,0,0,0,0,0C40.19,12.79,38.42,14.56,36.23,14.56L36.23,14.56z"
              fill="currentColor"
            />
            <path
              d="M74.35,4.4c-3.41,0-6.18,2.77-6.18,6.18s2.77,6.18,6.18,6.18c3.41,0,6.18-2.77,6.18-6.18S77.77,4.4,74.35,4.4L74.35,4.4z M74.35,14.56c-2.19,0-3.96-1.77-3.96-3.96s1.77-3.96,3.96-3.96c2.19,0,3.96,1.77,3.96,3.96c0,0,0,0,0,0C78.31,12.78,76.54,14.56,74.35,14.56z"
              fill="currentColor"
            />
            <path
              d="M28.14,5.05h-2.17v0.84c-1.11-0.95-2.53-1.47-3.99-1.47c-0.07,0-0.13,0-0.2,0c-3.41,0.11-6.08,2.96-5.97,6.37c0.11,3.33,2.84,5.98,6.18,5.98c1.65,0,2.84-0.64,3.7-1.54v1.08h2.47V5.05z M22.17,14.56c-2.19,0-3.96-1.77-3.96-3.96c0-2.19,1.77-3.96,3.96-3.96c2.19,0,3.96,1.77,3.96,3.96c0,0,0,0,0,0C26.13,12.79,24.36,14.56,22.17,14.56L22.17,14.56z"
              fill="currentColor"
            />
            <path
              d="M53.59,12.35c-0.97,1.96-3.34,2.77-5.3,1.8c-1.96-0.97-2.77-3.34-1.8-5.3c0.97-1.96,3.34-2.77,5.3-1.8c0.74,0.37,1.35,0.95,1.74,1.68l2.1-0.79c-1.45-3.09-5.13-4.41-8.22-2.96s-4.41,5.13-2.96,8.22c1.45,3.09,5.13,4.41,8.22,2.96c1.33-0.63,2.39-1.7,2.99-3.04L53.59,12.35z"
              fill="currentColor"
            />
            <ellipse
              fill="currentColor"
              cx="58.29"
              cy="1.52"
              rx="1.53"
              ry="1.52"
            />
          </g>
        </svg>

        {shortName && fullName && envVisible && (
          <div
            className="text-xs font-bold absolute top-1/2 right-0 rounded-sm leading-none transform -translate-y-1/2 translate-x-full -mr-1.5 bg-blue-500 text-white p-1"
            onClick={onEnvClicked}
            title={fullName}
          >
            {shortName}
          </div>
        )}
      </div>
    </div>
  )
}
