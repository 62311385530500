import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { LocaleFlag } from '~/components/LocaleFlag/LocaleFlag'
import { LocaleSwitcher } from '~/components/LocaleSwitcher/LocaleSwitcher'
import { Btn } from '~/components/Scl/Form/Button/Btn'
import { ChevronDownIcon } from '~/components/Scl/Icon/ChevronDownIcon'
import { Container } from '~/components/Scl/Layout/Container/Container'
import { CapcitoLogo } from '~/components/Scl/Logo/CapcitoLogo'
import { CollapseProvider } from '~/components/Scl/Tools/Collapse/CollapseProvider'
import { FadeScaleTransition } from '~/components/Scl/Tools/Transitions/FadeScaleTransition'
import { HasClassName } from '~/models/HasClassName'

export interface Props extends HasClassName {

}

export const GlobalPartnerFooter: FunctionComponent<Props> = ({ className,}) => {
  const { asPath } = useRouter()
  const current = asPath.split('/')[1]

  return (
    <div className="py-16 bg-indigo-500">
      <Container
        className="flex justify-between"
        width="2xl"
      >
        <CapcitoLogo svgClass="h-8 text-white" />

        <CollapseProvider
          initialState={false}
        >
          {({ open, toggle }) => (
            <>
              <Btn
                className="relative flex w-24 h-12 ml-auto"
                theme="outline-white"
                onClick={toggle}
              >
                <LocaleFlag
                  className="absolute left-0 w-6 ml-5 transform -translate-y-1/2 top-1/2"
                  locale={{ code: current }}
                />

                <ChevronDownIcon className="absolute right-0 w-5 h-5 mr-5 transform -translate-y-1/2 top-1/2" />
              </Btn>

              <FadeScaleTransition>
                { open && <LocaleSwitcher current={current} /> }
              </FadeScaleTransition>
            </>
          )}
        </CollapseProvider>
      </Container>
    </div>
  )
}
