import { Container } from 'components/Scl/Layout/Container/Container'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { TextUsps } from 'components/Storyblok/Partials/TextUsps'
import { Caption } from 'components/Scl/Typography/Caption'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import Image from 'next/image'
export interface Props extends Blok {
  subHeading: any,
  heading: any[],
  text: any,
  asset: any,
  usps: any[],
}

export const NumberSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      background="IVORY"
      size="lg"
    >
      <Container width="2xl">
        <div className="flex flex-col-reverse lg:flex-row lg:px-10">
          <div className="pt-16 lg:w-1/2">
            <Caption className="mb-2">
              { blok.subHeading }
            </Caption>

            <HeadingStoryblok
              className="mb-4"
              blok={blok.heading[0]}
            />

            <div className="lg:max-w-lg">
              { blok.text }
            </div>
          </div>

          <div className="relative w-full lg:w-1/2">
            <img
              src={blok.asset.filename}
              alt={blok.asset.alt}
              className="top-0 left-0 w-full -mt-12 lg:absolute lg:-mt-40"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3 lg:mt-24">
          { blok.usps.map((usp: any, index: number) => (
            <TextUsps
              key={usp._uid}
              blok={usp}
            />
          )) }
        </div>
      </Container>
    </SpacingWrapper>
  )
}
