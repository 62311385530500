import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { FunctionComponent, useEffect, useState } from 'react'
import { BlokProps, Blok } from '@storyblok/react'
import { HasClassName } from 'models/HasClassName'
import { Icon } from '~/components/Icon/Icon'
import classNames from 'classnames'

export interface Props extends Blok {
  theme?: 'INDIGO' | 'IVORY'
  icon: any
  text: any,
}

export const IconUsp: FunctionComponent<BlokProps<Props> & HasClassName> = ({ blok }) => {
  const renderer = {
    br: () => '<span class="mr-1 md:block md:mr-0"></span>',
  }

  return (
    <div
      className={classNames(
        'flex flex-row items-center p-4 rounded-md sm:flex-col sm:text-center md:text-center sm:p-6',
        blok.theme === 'INDIGO' ? 'bg-indigo-450 text-white' : 'bg-ivory-300'
      )}
    >
      <Icon
        className="w-6 h-6 mr-4 text-blue-500 sm:mr-0 sm:mb-3"
        icon={ blok.icon }
      />

      <div className="font-bold sm:text-lg lg:w-10/12">
        <MarkdownRenderer
          markdown={blok.text}
          renderer={renderer}
        />
      </div>
    </div>
  )
}
