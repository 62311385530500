enum GTMEvent {
  ONBOARDING_SKIPPED_STEP = 'Onboarding Skipped Step',
  COMPANY_IDENTIFIED = 'Company Identified',
  USER_REGISTERED = 'User Registered',
  HIT_ERROR_PAGE = 'Hit Error Page',
  LEAD_FORM = 'Lead Form',
  ROUTING = 'Routing',
}

export default GTMEvent
