import throttle from 'lodash-es/throttle'
import { RefObject, useEffect, useState } from 'react'

export const useMenu = (target: RefObject<Element | undefined>) => {
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', throttle(() => {
      const targetHeight = target?.current?.clientHeight ?? 0

      if (window.pageYOffset > targetHeight || window.innerWidth < 1024) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }, 100))
  }, [target])

  return {
    scrolled,
  }
}
