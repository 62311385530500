import { CollapseList } from 'components/Storyblok/Partials/CollapseList'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { BlokProps, Blok } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  collapse: any[],
}

export const FaqSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  return (
    <SpacingWrapper
      className={className}
      background={blok.background}
      size="md"
    >
      <Container width="2xl">
        <HeadingStoryblok
          className="mb-12 text-center"
          blok={blok.heading[0]}
        />

        <CollapseList
          blok={blok.collapse[0]}
        />
      </Container>
    </SpacingWrapper>
  )
}
