import { useRouter } from "next/router"

interface ReturnType {
  culture: string
}

export function useLocale(): ReturnType {
  const { asPath } = useRouter()
  const cleanPath = asPath.split('?')[0]

  return {
    culture: (cleanPath.split('/')[1] ? cleanPath.split('/')[1] : 'sv')
  }
}
