import { Props as GlobalNavigationProps } from 'components/Storyblok/Partials/Global/GlobalNavigation'
import { Props as GlobalFooterProps } from 'components/Storyblok/Partials/Global/GlobalFooter'
import { Props as NumberSectionProps } from 'components/Storyblok/NumberSection'
import { Props as CookieConsentProps } from 'components/CookieConsent/CookieConsent'
import { createContext } from 'react'

export interface GlobalContextContract {
  navigation: GlobalNavigationProps | null
  blogFooter: NumberSectionProps | null
  footer: GlobalFooterProps | null
  cookieConcent: CookieConsentProps | null
}

export const GlobalContext = createContext<GlobalContextContract>({
  navigation: null,
  blogFooter: null,
  footer: null,
  cookieConcent: null,
})
