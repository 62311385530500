import { FunctionComponent, ReactNode } from 'react'
import { HasClassName } from 'models/HasClassName'
import { TagType } from 'enums/TagType'
import classNames from 'classnames'

interface Props extends HasClassName {
  tag?: TagType
  size?: object | string
  children: ReactNode
}

enum Size {
  Huge = 'huge',
  Large = 'large',
  Regular = 'regular',
  Small = 'small',
  Tiny = 'tiny',
  Micro = 'micro',
}

function mapWeightClass(size: string): string {
  switch (size) {
    case Size.Huge:
    case Size.Large:
      return 'font-bold'
    default:
      return 'font-semibold'
  }
}

function mapSizeClass(size: string): string {
  switch (size) {
    case Size.Huge:
      return 'text-6xl'
    case Size.Large:
      return 'text-5xl'
    case Size.Regular:
      return 'text-4xl'
    case Size.Small:
      return 'text-3xl'
    case Size.Tiny:
      return 'text-2xl'
    case Size.Micro:
      return 'text-xl'
    default:
      return ''
  }
}

export const Heading: FunctionComponent<Props> = ({ tag = 'div', size = Size.Regular, className, children }) => {
  const Tag = tag

  const classes: string[] = []
  const sizeObj: object = typeof size === 'string' ? { base: size } : size

  if (!('base' in sizeObj)) {
    Object.assign(sizeObj, { base: Size.Regular })
  }

  Object.keys(sizeObj).forEach((key, index) => {
    const values = Object.values(sizeObj)
    const prefix = key !== 'base' ? `${key}:` : ''

    classes.push(`${prefix}${mapSizeClass(values[index])}`)
    classes.push(`${prefix}${mapWeightClass(values[index])}`)
  })


  return (
    <Tag className={classNames('font-serif leading-none', [...classes], className)}>
      {children}
    </Tag>
  )
}
