import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  body: string
}

export const BlogText: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const renderer = {
    paragraph: (text: string) => `<p class="mt-6">${text}</p>`,
  }

  return (
    <div className="max-w-screen-md px-6 mx-auto mt-6 text-indigo-500">
      <MarkdownRenderer markdown={blok.body} renderer={renderer} />
    </div>
  )
}

