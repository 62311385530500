import { MarkdownRenderer } from 'components/Scl/Form/Conversational/MarkdownRenderer'
import { HeadingStoryblok } from 'components/Storyblok/HeadingStoryblok'
import { BlokProps, Blok, StoryblokComponent } from '@storyblok/react'
import { Container } from 'components/Scl/Layout/Container/Container'
import { SpacingWrapper } from 'components/Storyblok/SpacingWrapper'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

export interface Props extends Blok {
  background: any,
  heading: any[],
  text: any,
  form: any[]
}

export const FormSection: FunctionComponent<BlokProps<Props> & HasClassName> = ({ className, blok }) => {
  const form = blok.form[0]
  const renderer = {
    list: (body: string) => `<ul class="mt-5 space-y-4 font-bold list-check">${body}</ul>`,
  }

  return (
    <SpacingWrapper
      className={className}
      background={blok.background}
      size="xs"
    >
      <Container width="xl">
        <div className="flex flex-wrap items-center md:flex-no-wrap">
          <div className="w-full mb-10 lg:w-1/2 lg:mb-0">
            <HeadingStoryblok
              className="mb-6"
              blok={blok.heading[0]}
            />

            <div className="mb-8 text-lg md:max-w-sm">
              <MarkdownRenderer
                markdown={blok.text}
                renderer={renderer}
              />
            </div>
          </div>

          <div className="w-full lg:w-1/2">
            <StoryblokComponent
              className="lg:-mt-12"
              blok={form}
            />
          </div>
        </div>
      </Container>
    </SpacingWrapper>
  )
}
