import { HasClassName } from 'models/HasClassName'
import { FunctionComponent, ReactNode } from 'react'
import classNames from 'classnames'

export interface Props extends HasClassName {
  children: ReactNode
}

export const Sticker: FunctionComponent<Props> = ({ children, className }) => {
  return (
    <div className={classNames('flex flex-col items-center justify-center p-1 text-center transform rounded-full h-22 w-22', className)}>
      <div className='flex-shrink-0'>
        {children}
      </div>
    </div>
  )
}
