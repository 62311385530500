import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'

interface Props extends HasClassName {
  locale: {
    code: string
  }
}

export const LocaleFlag: FunctionComponent<Props> = ({ locale, className }) => {
  return (
    <img
      src={`/assets/locales/${locale.code}.png`}
      className={className}
      alt={locale.code}
    />
  )
}
