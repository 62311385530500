import { Btn } from 'components/Scl/Form/Button/Btn'
import { Blok, BlokProps } from '@storyblok/react'
import { Anchor } from '~/components/Anchor/Anchor'
import { FunctionComponent } from 'react'
import Image from 'next/image'

export interface Props extends Blok {
  text: string
  asset: any
  title: any
  cta: any
}

export const BlogVerticalCta: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  return (
    <div className="w-full p-6 rounded bg-ivory-500 vertical-cta-max-w">
      <img
        src={blok.asset.filename}
        alt={blok.asset.alt}
        className="w-full mb-4"
      />

      <div className="mb-3 font-serif text-2xl font-semibold leading-none">
        {blok.title}
      </div>

      <div className="mb-6 text-sm">
        {blok.text}
      </div>

      <Anchor
        dataTrack={blok.cta[0].trackingId}
        className="block text-center"
        to={blok.cta[0].link}
      >
        <Btn>
          {blok.cta[0].text}
        </Btn>
      </Anchor>
    </div>
  )
}
