import { FunctionComponent, useState, useEffect, ReactNode, forwardRef, Ref } from 'react'
import { FormLabel } from 'components/Scl/Typography/FormLabel'
import { HasClassName } from 'models/HasClassName'
import classNames from 'classnames'

export interface Props extends HasClassName {
  name: string,
  label?: string,
  disabled?: boolean,
  placeholder?: string,
  help?: string,
  errors?: string[],
  type?: string,
  value?: string,
  iconLeft?: ReactNode,
  iconRight?: ReactNode,
  innerRef?: Ref<HTMLInputElement>
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void,
  onInput? (value: string): void,
}

const FieldComponent: FunctionComponent<Props> = (props) => {
  const { className, name, label, value = '', disabled, placeholder, help, errors = [], type = 'text', onChange, onClick, onFocus: onFocusHandler, onBlur: onBlurHandler, onInput: onInputHandler, iconLeft, iconRight, innerRef } = props

  const [state, setState] = useState({
    value: value,
    focus: false,
    hover: false,
  })

  useEffect(function updateValue() {
    setState({ ...state, value })
  }, [value])

  const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement
    setState({ ...state, value: target.value })

    onInputHandler && onInputHandler(target.value)
  }

  const hasValue = state.value && state.value !== ''
  const hasError = errors.length > 0
  const labelClasses = props.disabled ? 'opacity-30' : 'text-indigo-500'

  const iconLeftClasses = ((state.focus || hasValue) && !disabled)
    ? 'text-indigo-500'
    : 'text-indigo-500 opacity-50'

  const inputFieldClasses = ((state.focus || hasValue) && !disabled)
    ? 'text-indigo-500'
    : 'placeholder-indigo-500 opacity-50'

  const borderBottomClasses = (disabled)
    ? 'border-dotted border-gray-200'
    : hasError
    ? 'border-red-500'
    : state.focus
    ? 'border-blue-500'
    : 'border-gray-200'

  const iconRightClasses = (hasError && !disabled)
    ? 'text-red-500'
    : ((state.focus || hasValue) && !disabled)
    ? 'text-blue-500'
    : 'text-indigo-500 opacity-30'

  const smallTextClasses = (hasError && !disabled)
    ? 'text-red-500'
    : 'text-indigo-500 opacity-30'

  const smallText = (hasError && !disabled)
    ? errors[0]
    : help ?? null

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setState({ ...state, focus: true })
    onFocusHandler && onFocusHandler(event)
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setState({ ...state, focus: false })
    onBlurHandler && onBlurHandler(event)
  }

  const onMouseEnter = () => {
    setState({ ...state, hover: true })
  }

  const onMouseLeave = () => {
    setState({ ...state, hover: false })
  }

  return (
    <>
      <div>
        { label &&
          <FormLabel
            htmlFor={name}
          >
            { label }
          </FormLabel>
        }

      <div
        className={ classNames('flex items-center border-b-2 h-11', borderBottomClasses) }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        { iconLeft &&
          <div className={classNames('flex-shrink-0 w-6 h-6 mr-2', iconLeftClasses)}>
            { iconLeft }
          </div>
        }

        <input
          className={classNames('w-full text-lg leading-none bg-transparent focus:outline-none', inputFieldClasses)}
          placeholder={placeholder}
          onInput={(event: any) => onInput(event)}
          disabled={disabled}
          value={state.value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          onClick={onClick}
          name={name}
          type={type}
          id={name}
          ref={innerRef}
        />

        { iconRight &&
          <div className={classNames('flex-shrink-0 w-6 h-6 ml-2', iconRightClasses)}>
            { iconRight }
          </div>
        }
      </div>

      { smallText &&
        <small className={classNames('block mt-2 text-sm', smallTextClasses)}>
          { smallText }
        </small>
      }
    </div>
  </>
  )
}

export const Field = forwardRef<HTMLInputElement, Props>(function Field (props, ref) {
  return (
    <FieldComponent {...props} innerRef={ref} />
  )})
