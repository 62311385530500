import { Container } from '~/components/Scl/Layout/Container/Container'
import { CapcitoLogo } from '~/components/Scl/Logo/CapcitoLogo'
import { PhoneIcon } from '~/components/Scl/Icon/PhoneIcon'
import { HasClassName } from 'models/HasClassName'
import { FunctionComponent } from 'react'
import { useLink } from '~/hooks/link'
import classNames from 'classnames'
import NextLink from 'next/link'

interface Props extends HasClassName {
  phone: string
  logo?: Record<any, any>
  text?: string
}

export const GlobalPartnerNavigation: FunctionComponent<Props> = ({ phone, logo, text, className }) => {
  const { link, tel } = useLink()

  return (
    <nav className={classNames('fixed top-0 left-0 z-30 w-full bg-white border-b-2 border-indigo-500 shadow border-opacity-10', className)}>
      <div className="h-20">
        <Container
          className="flex items-center justify-between h-full"
          width="2xl"
        >
          <div className="flex justify-between w-full lg:justify-start lg:w-auto">
            <div className="w-24">
              <NextLink href={link('/')}>
                <a>
                  <CapcitoLogo svgClass="w-20" />
                </a>
              </NextLink>
            </div>

            <a
              className="flex items-center font-bold leading-none"
              data-track="Navigation/Phone"
              href={tel(phone)}
            >
              <PhoneIcon className="w-4 h-4 mr-1" />

              <span className="mt-px">
                {phone}
              </span>
            </a>
          </div>

          {
            logo && text && (
              <div className="items-center hidden lg:flex">
                {text && (
                  <p className="mt-1 mr-4 leading-none text-indigo-500 text-opacity-50">
                    {text}
                  </p>
                )}

                {logo && (
                  <div>
                    <img
                      style={{ maxWidth: '85px', maxHeight: '40px' }}
                      src={logo.filename}
                      alt={logo.alt}
                    />
                  </div>
                )}
              </div>
            )}
        </Container>
      </div>

      <div className="h-12 border-t border-indigo-500 lg:hidden border-opacity-10">
        <Container
          className="flex items-center justify-between h-full"
          width="2xl"
        >
          <p className="text-indigo-500 text-opacity-50">
            {text}
          </p>

          <div>
            <img
              style={{ maxWidth: '85px', maxHeight: '40px' }}
              src={logo?.filename}
              alt={logo?.alt}
            />
          </div>
        </Container>
      </div>
    </nav>
  )
}
