import { useTranslations } from '~/hooks/translations'
import { useLocale } from '~/hooks/locale'

interface ReturnType {
  config: Record<string, unknown>
}

export function useValidation(): ReturnType {
  const { culture } = useLocale()
  const translations = useTranslations(culture)

  const config = {
    mixed: {
      required: translations?.required
    },
    string: {
      email: translations?.email,
    },
  }

  return {
    config
  }
}
