import classNames from 'classnames'
import { HasClassName } from 'models/HasClassName'
import { useRouter } from 'next/router'
import { FunctionComponent } from 'react'
import { LocaleFlag } from '../LocaleFlag/LocaleFlag'

interface Props extends HasClassName {
  current: string
}

export const LocaleSwitcher: FunctionComponent<Props> = ({ current }) => {
  const { asPath, route, ...rest } = useRouter()

  // TODO: update locales from storyblok, global config?
  const locales = [{ code: 'sv', name: 'Svenska'}, { code: 'en', name: 'English'}]
  const newPath = asPath.replace(`/${current}`, '')


  return (
    <div className="w-56 p-4 mt-2 ml-auto bg-white rounded shadow">
      {locales.map(locale => (
        <a
          className={classNames('flex items-center px-4 py-3 font-semibold text-indigo-500 rounded', [
            locale.code === current
              ? 'bg-blue-100 text-blue-500'
              : 'cursor-pointer hover:text-blue-500'
          ])}
          href={`/${locale.code}${newPath}`}
          key={locale.code}

        >
          <LocaleFlag
            locale={locale}
            className="w-6 mr-3"
          />

          {locale.name}
        </a>
      ))}
    </div>
  )
}
