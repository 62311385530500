import { QuoteDots } from '~/components/QuoteDots/QuoteDots'
import { Blok, BlokProps } from '@storyblok/react'
import { FunctionComponent } from 'react'
import classNames from 'classnames'

export interface Props extends Blok {
  text: string
  size: string
  dots: any
}

export const BlogTitle: FunctionComponent<BlokProps<Props>> = ({ blok }) => {
  const getHeadingSize = () => {
    switch (blok.size) {
      case 'text-4xl':
        return 'h2'
      case 'text-3xl':
        return 'h3'
    }

    throw new Error(`The given value [${blok.size}] is not a valid size`)
  }

  const Heading = getHeadingSize()

  return (
    <div
      className={classNames('max-w-screen-md mx-auto mt-8 px-6', `${blok.size}`)}
    >
      {blok.dots && (
        <QuoteDots
          className="mb-6"
        />
      )}

      <Heading className="font-serif font-semibold leading-none">
        {blok.text}
      </Heading>
    </div >
  )
}
